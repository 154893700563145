import React, { useCallback, useState } from 'react';
import { post } from '../../../services/httpService';
import { formatDateTimeToString } from '../../../services/formatDate';
import { openNotificationError } from '../../Notification/Notification';
import { shopConfig } from '../../../Globals/ShopConfig';
import { ProviderChildProps } from '../Provider';
import Translator from '../../../services/translator';
import getBasketSession from '../../../services/basketSession';
import { pagesConstant } from '../../../Constants/Constants';
import ModalEmisys from '../../Modal/ModalEmisys';
import './Debug.css';

const Debug = (props: ProviderChildProps) => {
  const [loading, setLoading] = useState(false);
  const basketSession = getBasketSession();

  const debugBankConfirm = useCallback(() => {
    setLoading(true);

    post(shopConfig.router.paymentDebugConfirmUrl, {
      id: props.provider.id,
      signature: props.provider.signature,
    })
      .then(() => {
        props.handleRedirect(pagesConstant.payment.authorized);
      })
      .catch((error) => {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          error.body?.message
        );
      })
      .finally(() => setLoading(false));
  }, [props]);

  const debugConfirm = useCallback(() => {
    setLoading(true);
    post(shopConfig.router.paymentDebugValidateUrl, {
      id: props.provider.id,
      signature: props.provider.signature,
    })
      .then(() => {
        props.handleRedirect(pagesConstant.payment.success);
      })
      .catch((error) => {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          error.body?.message
        );
      })
      .finally(() => setLoading(false));
  }, [props]);

  const debugCancel = useCallback(() => {
    setLoading(true);
    post(shopConfig.router.paymentDebugCancelUrl, {
      id: props.provider.id,
      signature: props.provider.signature,
    })
      .then(() => {
        if (props.handleClose) {
          props.handleClose();
        } else {
          props.handleRedirect(pagesConstant.payment.failure);
        }
      })
      .catch((error) => {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          error.body?.message
        );
      })
      .finally(() => setLoading(false));
  }, [props]);

  return (
    <ModalEmisys
      title={
        <div className="basketInfo">
          <strong>{Translator.trans('order.basketNumber')}</strong>
          <span>{basketSession?.id}</span>
        </div>
      }
      closable={false}
      maskClosable={false}
      open={props.isOpen}
      footer={null}
      width={960}
      centered
    >
      <div className="shop-modal-body">
        <div className="basketInfo">
          <div>
            <strong>{Translator.trans('order.purchaseDate')}</strong>
            <span>
              {props.currentBasket?.confirmedAt &&
                formatDateTimeToString(props.currentBasket?.confirmedAt)}
            </span>
          </div>

          <div>
            <strong>{Translator.trans('order.buyer')}</strong>
            <span>{props.provider.customer.fullName}</span>
          </div>

          <div>
            <strong>{Translator.trans('order.numItems')}</strong>
            <span>{props.currentBasket?.totalItems}</span>
          </div>

          {props.provider.customer && (
            <div className={'debug-user-info'}>
              <div>
                <strong>{Translator.trans('user.property.email')}</strong>
                <span>{props.provider.customer.email}</span>
              </div>
              <div>
                <strong>{Translator.trans('user.property.phonenumber')}</strong>
                <span>{props.provider.customer.phonenumber}</span>
              </div>

              {props.provider.billingAddress && (
                <div className={'debug-user-adress'}>
                  <div>
                    <strong>
                      {Translator.trans('address.property.country')}
                    </strong>
                    <span>{props.provider.billingAddress.country}</span>
                  </div>
                  <div>
                    <strong>{Translator.trans('address.property.city')}</strong>
                    <span>{props.provider.billingAddress.locality}</span>
                  </div>
                  <div>
                    <strong>
                      {Translator.trans('address.property.street')}
                    </strong>
                    <span>{props.provider.billingAddress.getFullStreet()}</span>
                  </div>
                  <div>
                    <strong>
                      {Translator.trans('address.property.zipCode')}
                    </strong>
                    <span>{props.provider.billingAddress.postalCode}</span>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="debug-provider-info">
            {Translator.trans('payment.debugPayment.paymentProviderInfo')}
          </div>

          <div className="debug-provider-buttons">
            <button
              className="btn btn-success"
              onClick={debugConfirm}
              disabled={loading}
            >
              {Translator.trans('order.button.confirmAndPay', {
                amount: props.provider.amount.formattedPrice,
              })}
            </button>
            <button
              className="btn btn-warning"
              onClick={debugBankConfirm}
              disabled={loading}
            >
              {Translator.trans('payment.debugPayment.bankConfirm')}
            </button>
            <button
              className="btn btn-danger"
              onClick={debugCancel}
              disabled={loading}
            >
              {Translator.trans('payment.debugPayment.cancel')}
            </button>
          </div>
        </div>
      </div>
    </ModalEmisys>
  );
};

export default Debug;
