import { paymentConstants } from '../constants/payment.constants';
import PaymentResultModel from '../../Models/PaymentResultModel';

const initialState = {
  payment: null,
};

const payment = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentConstants.GET_PAYMENT_INFO_REQUEST: {
      return {
        ...state,
        payment: {
          info: null,
          loading: true,
          error: false,
        },
      };
    }

    case paymentConstants.GET_PAYMENT_INFO_SUCCESS: {
      return {
        ...state,
        payment: {
          info: new PaymentResultModel(action.result),
          loading: false,
          error: false,
        },
      };
    }

    case paymentConstants.GET_PAYMENT_INFO_FAILURE: {
      return {
        ...state,
        payment: {
          info: null,
          loading: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export default payment;
