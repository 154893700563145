import React from 'react';
import { formatMoney } from '../../services/formatMoney';
import SimplePrice from '../Price/SimplePrice';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import taxDetailModel from '../../Models/taxDetailModel';
import { currencyPrecision } from '../../Constants/Constants';
import './BasketPriceDetail.css';

interface BasketPriceDetailProps {
  subTotal: number;
  feeAmount: number;
  totalPriceWithoutVat: number;
  totalPriceWithVat: number;
  taxDetails: taxDetailModel[];
}

const BasketPriceDetail = (props: BasketPriceDetailProps) => {
  const locale = shopConfig.locale.locale;
  const currency = shopConfig.locale.currencyCode;
  const {
    subTotal,
    feeAmount,
    totalPriceWithoutVat,
    totalPriceWithVat,
    taxDetails,
  } = props;

  return (
    <>
      {Math.abs(totalPriceWithVat) >= currencyPrecision && (
        <>
          {feeAmount >= currencyPrecision && (
            <div className={'subtotal-container'}>
              <div className="fee-container">
                <div>{Translator.trans('accounting.amount.sub_total')}</div>
                <div>{formatMoney(currency, locale, subTotal)}</div>
              </div>
              <div className="fee-container">
                <div>{Translator.trans('accounting.amount.fees')}</div>
                <div>{formatMoney(currency, locale, feeAmount)}</div>
              </div>
            </div>
          )}

          <div className="fee-container">
            <div>{Translator.trans('accounting.amount.total_exclTax')}</div>
            <div>
              {!isNaN(totalPriceWithoutVat) &&
                formatMoney(currency, locale, totalPriceWithoutVat)}
            </div>
          </div>

          {taxDetails && taxDetails.length > 0 ? (
            taxDetails.map((taxDetail, index) => (
              <div key={index} className="fee-container vatDetails">
                <div>
                  {taxDetail.name !== ''
                    ? taxDetail.name
                    : Translator.trans('accounting.vat')}
                </div>
                <div>{formatMoney(currency, locale, taxDetail.amount)}</div>
              </div>
            ))
          ) : (
            <div className="fee-container vatDetails">
              <div>{Translator.trans('accounting.amount.vat')}</div>
              <div>{formatMoney(currency, locale, 0)}</div>
            </div>
          )}
        </>
      )}

      <div className="fee-container">
        <div className="totalTitle">
          {Translator.trans('accounting.amount.total_inclTax')}
        </div>
        <div className="totalTitle">
          <SimplePrice price={totalPriceWithVat} currency={currency} />
        </div>
      </div>
    </>
  );
};

export default BasketPriceDetail;
