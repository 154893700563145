import { accommodationConstants } from '../constants/accommodation.constants';

export const getOccupantTypesRequest = () => ({
  type: accommodationConstants.GET_OCCUPANT_TYPES_REQUEST,
});

export const getOccupantTypesSuccess = (occupantTypes: object) => ({
  type: accommodationConstants.GET_OCCUPANT_TYPES_SUCCESS,
  occupantTypes,
});

export const getOccupantTypesFailure = (error: object) => ({
  type: accommodationConstants.GET_OCCUPANT_TYPES_FAILURE,
  error,
});

export const getAccommodationListRequest = (params: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_LIST_REQUEST,
  params,
});

export const getAccommodationListSuccess = (accommodationList: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_LIST_SUCCESS,
  accommodationList,
});

export const getAccommodationListFailure = (error: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_LIST_FAILURE,
  error,
});

export const getAccommodationAssembliesRequest = (
  accommodationId: number,
  params: object
) => {
  return {
    type: accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_REQUEST,
    accommodationId,
    params,
  };
};

export const getAccommodationAssembliesSuccess = (assembliesList: []) => ({
  type: accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_SUCCESS,
  assembliesList,
});

export const getAccommodationAssembliesFailure = (error: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_FAILURE,
  error,
});

export const getAccommodationDetailRequest = (
  accommodationId: number,
  params: object
) => ({
  type: accommodationConstants.GET_ACCOMMODATION_DETAIL_REQUEST,
  accommodationId,
  params,
});

export const getAccommodationDetailSuccess = (accommodation: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_DETAIL_SUCCESS,
  accommodation,
});

export const getAccommodationDetailsFailure = (error: object) => ({
  type: accommodationConstants.GET_ACCOMMODATION_DETAIL_FAILURE,
  error,
});
