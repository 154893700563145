import Translator from '../services/translator';

export let genderList;

export const initGenderTranslation = () => {
  const genderMap = {
    M: Translator.trans('user.gender.M'),
    F: Translator.trans('user.gender.F'),
    O: Translator.trans('user.gender.other'),
    NA: Translator.trans('user.gender.na'),
  };

  genderList = Object.keys(genderMap).map((key) => ({
    value: key,
    label: genderMap[key],
  }));
};
