import React from 'react';
import { BasketStatusConstant } from '../../Constants/Constants';
import { Alert } from 'antd';
import Translator from '../../services/translator';
import './StatusComponent.css';

const StatusComponent = (props: {
  type: keyof typeof BasketStatusConstant;
}) => {
  switch (props.type) {
    case BasketStatusConstant.payment_accept:
      return (
        <Alert
          showIcon
          className={'status-component'}
          message={Translator.trans('basket.status.payment_accept')}
          type="success"
        />
      );
    case BasketStatusConstant.payment_via_invoice:
      return (
        <Alert
          showIcon
          className={'status-component'}
          message={Translator.trans('basket.status.payment_accept')}
          type="success"
        />
      );
    case BasketStatusConstant.awaiting_payment_online:
      return (
        <Alert
          className={'status-component'}
          message={Translator.trans('basket.status.awaiting_payment_online')}
          type="warning"
        />
      );
    case BasketStatusConstant.awaiting_payment_bank:
      return (
        <Alert
          className={'status-component'}
          message={Translator.trans('basket.status.awaiting_payment_online')}
          type="warning"
        />
      );

    case BasketStatusConstant.payment_check:
      return (
        <Alert
          className={'status-component'}
          message={Translator.trans('basket.status.payment_check')}
          type="warning"
        />
      );
    case BasketStatusConstant.draft:
      return (
        <Alert
          className={'status-component'}
          message={Translator.trans('basket.status.draft')}
          type="info"
        />
      );
    case BasketStatusConstant.cancel:
      return (
        <Alert
          showIcon
          className={'status-component'}
          message={Translator.trans('basket.status.cancel')}
          type="error"
        />
      );
    case BasketStatusConstant.expired:
      return (
        <Alert
          className={'status-component'}
          message={Translator.trans('basket.status.expired')}
          type="error"
        />
      );
    default:
      return <>{props.type}</>;
  }
};

export default StatusComponent;
