import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CONSTANTSCMS, pagesConstant } from '../../Constants/Constants';
import Translator from '../../services/translator';
import audienceLogo from '../../assets/images/audience.png';
import MediaQuery from 'react-responsive';
import CookieContainer from '../CookieContainer/CookieContainer';
import { getCookieConsentValue } from 'react-cookie-consent';
import './Footer.css';

const Footer = () => {
  const [showCookieContent, setShowCookieContent] = useState(false);
  const showCookiesConsent = () => {
    const consentValue = getCookieConsentValue();
    if (consentValue !== undefined) {
      setShowCookieContent(true);
    }
  };

  return (
    <footer className="footer-react">
      <nav className="footerContainer">
        <div id="imageLogofooter">
          <a href="https://www.emisys.com" className="poweredby">
            <img src={audienceLogo} alt="byemisys" />
          </a>
        </div>
        <MediaQuery minWidth={769}>
          <ul className="footerLinks">
            <li onClick={showCookiesConsent}>
              <a href="#" onClick={(e) => e.preventDefault()}>
                {Translator.trans('footer.cookies')}
              </a>
            </li>
            <li>
              <Link to={pagesConstant.page + CONSTANTSCMS.TERMSURL}>
                {Translator.trans('footer.cgv')}
              </Link>
            </li>
            <li>
              <Link to={pagesConstant.page + CONSTANTSCMS.LEGALURL}>
                {Translator.trans('footer.legal_notice')}
              </Link>
            </li>
            <li>
              <Link to={pagesConstant.page + CONSTANTSCMS.POLICYURL}>
                {Translator.trans('footer.policy')}
              </Link>
            </li>
            <li>
              <Link to={pagesConstant.page + CONSTANTSCMS.INFOSURL}>
                {Translator.trans('footer.faq')}
              </Link>
            </li>
          </ul>
        </MediaQuery>
      </nav>

      <CookieContainer
        showCookieContent={showCookieContent}
        hideContent={() => setShowCookieContent(false)}
      />
    </footer>
  );
};

export default Footer;
