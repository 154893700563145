import { orderConstants } from '../constants/order.constants';
import PaymentOverviewModel from '../../Models/OrderOverview/PaymentOverviewModel';
import OrderConfirmModel from '../../Models/OrderOverview/OrderConfirmModel';
import BasketModel from '../../Models/BasketModel';
import OrderModel from '../../Models/OrderModel';

const initialState = {
  ordersList: null,
  orderDetail: null,
  orderOverview: null,
  orderConfirm: null,
  pendingOrder: null,
};

let overviewOrder = null;
let confirmOrder = null;
const order = (state = initialState, action: any) => {
  switch (action.type) {
    case orderConstants.GET_ORDERS_LIST_REQUEST: {
      return {
        ...state,
        ordersList: { list: [], error: false, loading: true },
      };
    }

    case orderConstants.GET_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        ordersList: {
          list: action.orders.map((order: any) => new OrderModel(order)),
          error: false,
          loading: false,
        },
      };
    }

    case orderConstants.GET_ORDERS_LIST_FAILURE: {
      return {
        ...state,
        ordersList: {
          list: [],
          error: true,
          errorMessage: action.error.body?.message,
          loading: false,
        },
      };
    }

    case orderConstants.GET_ORDER_DETAIL_REQUEST: {
      return {
        ...state,
        orderDetail: { order: null, error: false, loading: true },
      };
    }

    case orderConstants.GET_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        orderDetail: {
          order: new OrderModel(action.order),
          error: false,
          loading: false,
        },
      };
    }

    case orderConstants.GET_ORDER_DETAIL_FAILURE: {
      return {
        ...state,
        orderDetail: {
          order: null,
          error: true,
          errorMessage: action.error.body?.message,
          loading: false,
        },
      };
    }

    case orderConstants.GET_ORDER_OVERVIEW_REQUEST: {
      return {
        ...state,
        orderOverview: { order: overviewOrder, error: false, loading: true },
      };
    }

    case orderConstants.GET_ORDER_OVERVIEW_SUCCESS: {
      overviewOrder = new PaymentOverviewModel(action.order);
      return {
        ...state,
        orderOverview: {
          order: overviewOrder,
          error: false,
          loading: false,
        },
      };
    }

    case orderConstants.GET_ORDER_OVERVIEW_FAILURE: {
      return {
        ...state,
        orderOverview: {
          order: null,
          error: true,
          errorMessage: action.error.body?.message,
          loading: false,
        },
      };
    }

    case orderConstants.POST_ORDER_CONFIRM_REQUEST: {
      return {
        ...state,
        orderConfirm: { order: overviewOrder, error: false, loading: true },
      };
    }

    case orderConstants.POST_ORDER_CONFIRM_SUCCESS: {
      confirmOrder = new OrderConfirmModel(action.response);
      return {
        ...state,
        orderConfirm: {
          order: confirmOrder,
          error: false,
          loading: false,
        },
      };
    }

    case orderConstants.POST_ORDER_CONFIRM_FAILURE: {
      return {
        ...state,
        orderConfirm: {
          order: null,
          error: action.error.body,
          loading: false,
        },
      };
    }

    case orderConstants.GET_PENDING_ORDER_REQUEST: {
      return {
        ...state,
        pendingOrder: { order: null, error: false, loading: true },
      };
    }

    case orderConstants.GET_PENDING_ORDER_SUCCESS: {
      return {
        ...state,
        pendingOrder: {
          order: action.response.map((order) => new BasketModel(order)),
          error: false,
          loading: false,
        },
      };
    }

    case orderConstants.GET_PENDING_ORDER_FAILURE: {
      return {
        ...state,
        pendingOrder: {
          order: null,
          error: true,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default order;
