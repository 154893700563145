interface JsonPayload {
  [key: string]: String | JsonPayload;
}

interface FlatFormPayload {
  [key: string]: string;
}

export default function payloadToMultipartForm(
  payload: JsonPayload
): FlatFormPayload {
  return flattenPayload(payload, null);
}

function flattenPayload(
  payload: JsonPayload,
  baseKey: string | null
): FlatFormPayload {
  const flattened: FlatFormPayload = {};
  flattenPayloadObject(payload).forEach(([key, value]) => {
    const currentKey = createFormKey(baseKey, key);
    if (isFlattenable(value)) {
      Object.entries(flattenPayload(value, currentKey)).forEach(
        ([subKey, subValue]) => {
          flattened[subKey] = subValue;
        }
      );
    } else {
      flattened[currentKey] = value;
    }
  });
  return flattened;
}

function createFormKey(baseKey: string | null, key: string): string {
  if (baseKey === null) {
    return key;
  }
  return `${baseKey}[${key}]`;
}

function isFlattenable(payload: JsonPayload): boolean {
  if (typeof payload !== 'object') {
    return false;
  }
  if (payload instanceof File) {
    return false;
  }
  return true;
}

function flattenPayloadObject(payload: Array<any> | Object): Array<Array<any>> {
  const map: Array<Array<any>> = [];
  if (Array.isArray(payload)) {
    payload.forEach((value: any, index: number) => {
      map.push([index.toString(), value]);
    });
  } else {
    Object.keys(payload).forEach((key: string) => {
      map.push([key, payload[key]]);
    });
  }
  return map;
}
