import { basketConstants } from '../constants/basket.constants';

export const getBasketRequest = (params?) => ({
  type: basketConstants.GET_BASKET_REQUEST,
  params,
});

export const getBasketSuccess = (basket: object) => ({
  type: basketConstants.GET_BASKET_SUCCESS,
  basket,
});

export const getBasketFailure = (error: object) => ({
  type: basketConstants.GET_BASKET_FAILURE,
  error,
});

export const postBasketItemRequest = (basketItem: object) => ({
  type: basketConstants.POST_BASKET_ITEM_REQUEST,
  basketItem,
});

export const postBasketItemSuccess = (newItem: object) => ({
  type: basketConstants.POST_BASKET_ITEM_SUCCESS,
  newItem,
});

export const postBasketItemFailure = (error: object) => ({
  type: basketConstants.POST_BASKET_ITEM_FAILURE,
  error,
});

export const postBasketMultipleItemRequest = (basketItem: object) => ({
  type: basketConstants.POST_BASKET_MULTIPLE_ITEM_REQUEST,
  basketItem,
});

export const postBasketMultipleItemSuccess = (newItem: object) => ({
  type: basketConstants.POST_BASKET_MULTIPLE_ITEM_SUCCESS,
  newItem,
});

export const postBasketMultipleItemFailure = (error: object) => ({
  type: basketConstants.POST_BASKET_MULTIPLE_ITEM_FAILURE,
  error,
});

export const postBasketAccommodationRequest = (accommodation: object) => ({
  type: basketConstants.POST_BASKET_ACCOMMODATION_REQUEST,
  accommodation,
});

export const postBasketAccommodationSuccess = (newAccommodation: object) => ({
  type: basketConstants.POST_BASKET_ACCOMMODATION_SUCCESS,
  newAccommodation,
});

export const postBasketAccommodationFailure = (error: object) => ({
  type: basketConstants.POST_BASKET_ACCOMMODATION_FAILURE,
  error,
});

export const putBasketItemRequest = (basketItem: object) => ({
  type: basketConstants.PUT_BASKET_ITEM_REQUEST,
  basketItem,
});

export const putBasketItemSuccess = (updateItem) => ({
  type: basketConstants.PUT_BASKET_ITEM_SUCCESS,
  updateItem,
});

export const putBasketItemFailure = (error) => ({
  type: basketConstants.PUT_BASKET_ITEM_FAILURE,
  error,
});

export const deleteAllBasketRequest = () => ({
  type: basketConstants.DELETE_ALL_BASKET_REQUEST,
});

export const deleteAllBasketSuccess = (basket) => ({
  type: basketConstants.DELETE_ALL_BASKET_SUCCESS,
  basket,
});

export const deleteAllBasketFailure = (error) => ({
  type: basketConstants.DELETE_ALL_BASKET_FAILURE,
  error,
});

export const postBasketDiscountRequest = (basketDiscount) => ({
  type: basketConstants.POST_BASKET_DISCOUNT_REQUEST,
  basketDiscount,
});

export const postBasketDiscountSuccess = (newBasketWithDiscount) => ({
  type: basketConstants.POST_BASKET_DISCOUNT_SUCCESS,
  newBasketWithDiscount,
});

export const postBasketDiscountFailure = (error) => ({
  type: basketConstants.POST_BASKET_DISCOUNT_FAILURE,
  error,
});
