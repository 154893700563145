import { paymentMethodConstants } from '../constants/paymentMethod.constants';
import PaymentMethodModel from '../../Models/OrderOverview/PaymentMethodModel';

const initialState = {
  paymentMethodList: null,
};

let listPaymentMethod = null;
const paymentMethod = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentMethodConstants.GET_PAYMENTMETHOD_REQUEST: {
      return {
        ...state,
        paymentMethodList: {
          paymentMethod: listPaymentMethod,
          error: false,
          loading: true,
        },
      };
    }

    case paymentMethodConstants.GET_PAYMENTMETHOD_SUCCESS: {
      listPaymentMethod = PaymentMethodModel.setPaymentMethodModel(
        action.paymentMethod
      );
      return {
        ...state,
        paymentMethodList: {
          paymentMethod: listPaymentMethod,
          error: false,
          loading: false,
        },
      };
    }

    case paymentMethodConstants.GET_PAYMENTMETHOD_FAILURE: {
      return {
        ...state,
        paymentMethodList: { paymentMethod: null, error: true, loading: false },
      };
    }
    default:
      return state;
  }
};

export default paymentMethod;
