import { sectionsConstants } from '../constants/sections.constants';

export const setCurrentSection = (section: object) => ({
  type: sectionsConstants.SET_CURRENT_SECTION,
  section,
});

export const getSectionsRequest = () => ({
  type: sectionsConstants.GET_SECTIONS_REQUEST,
});

export const getSectionsSuccess = (sections: object) => ({
  type: sectionsConstants.GET_SECTIONS_SUCCESS,
  sections,
});

export const getSectionsFailure = (error: object) => ({
  type: sectionsConstants.GET_SECTIONS_FAILURE,
  error,
});
