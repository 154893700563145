import { accommodationConstants } from '../constants/accommodation.constants';

const initialState = {
  occupantTypesList: '',
  accommodationList: null,
  assembliesList: null,
  accommodationDetail: null,
};

const accommodation = (state = initialState, action: any) => {
  switch (action.type) {
    case accommodationConstants.GET_OCCUPANT_TYPES_REQUEST: {
      return {
        ...state,
        occupantTypesList: { occupantTypes: [], error: false, loading: true },
      };
    }

    case accommodationConstants.GET_OCCUPANT_TYPES_SUCCESS: {
      return {
        ...state,
        occupantTypesList: {
          occupantTypes: action.occupantTypes,
          error: false,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_OCCUPANT_TYPES_FAILURE: {
      return {
        ...state,
        occupantTypesList: { occupantTypes: null, error: true, loading: false },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_REQUEST: {
      return {
        ...state,
        accommodationList: { accommodations: [], error: false, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_SUCCESS: {
      return {
        ...state,
        accommodationList: {
          accommodations: action.accommodationList,
          error: false,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_FAILURE: {
      return {
        ...state,
        accommodationList: {
          accommodations: null,
          error: true,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_REQUEST: {
      return {
        ...state,
        assembliesList: { list: [], error: false, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_SUCCESS: {
      return {
        ...state,
        assembliesList: {
          list: action.assembliesList,
          error: false,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_FAILURE: {
      return {
        ...state,
        assembliesList: { list: null, error: true, loading: false },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_REQUEST: {
      return {
        ...state,
        accommodationDetail: { accommodation: [], error: false, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_SUCCESS: {
      return {
        ...state,
        accommodationDetail: {
          accommodation: action.accommodation,
          error: false,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_FAILURE: {
      return {
        ...state,
        accommodationDetail: {
          accommodation: null,
          error: true,
          loading: false,
          errorMsg: action.error.body.error,
        },
      };
    }

    default:
      return state;
  }
};

export default accommodation;
