import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { paymentConstants } from '../constants/payment.constants';
import { get, getUrl } from '../../services/httpService';
import {
  getPaymentInfoFailure,
  getPaymentInfoSuccess,
} from '../actions/payment.actions';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getPaymentInfoFetch(arg) {
  const url = shopConfig.router.paymentDetailUrl;
  return await get(getUrl(url, arg.params));
}

export function* fetchGetPaymentInfo(action) {
  try {
    const info = yield call(getPaymentInfoFetch, { params: action.params });
    yield put(getPaymentInfoSuccess(info));
  } catch (error) {
    yield put(getPaymentInfoFailure(error));
  }
}

export function* getPaymentInfo() {
  yield takeLatest(
    paymentConstants.GET_PAYMENT_INFO_REQUEST,
    fetchGetPaymentInfo
  );
}

export default function* paymentSaga() {
  yield all([fork(getPaymentInfo)]);
}
