export default class ShopAnalyticsModel {
  googleAnalytics: string | null;
  facebookPixel: string | null;
  googleTagManager: string | null;

  constructor(data: any) {
    this.googleAnalytics = data.googleAnalytics;
    this.facebookPixel = data.facebookPixel;
    this.googleTagManager = data.googleTagManager;
  }
}
