import { getBaseUrlWithoutLang } from './parseUrl';

/*-----------------------Basket session storage--------------------------*/

interface BasketStorageModel {
  signature: string;
  id: number;
  expires: string;
}

function getBasketStorageKey(): string {
  return 'basket+' + getBaseUrlWithoutLang(window.location.pathname);
}

export const getBasketStorage = (): BasketStorageModel | null => {
  const jsonToken = localStorage.getItem(getBasketStorageKey());
  if (!jsonToken) {
    return null;
  }
  return decodeBasketStorage(jsonToken);
};

export const setBasketStorage = (signature: string, id: number): void => {
  const date = new Date();
  const nextMonth = date.setMonth(date.getMonth() + 1);

  localStorage.setItem(
    getBasketStorageKey(),
    JSON.stringify({ signature, id, expires: new Date(nextMonth) })
  );
};

export const removeBasketStorage = (): void => {
  localStorage.removeItem(getBasketStorageKey());
};

const decodeBasketStorage = (jsonToken: string): BasketStorageModel | null => {
  try {
    const basketToken = JSON.parse(jsonToken);
    if (typeof basketToken !== 'object') {
      removeBasketStorage();
      return null;
    }
    const now = new Date();
    if (now >= new Date(basketToken.expires)) {
      removeBasketStorage();
      return null;
    }
    return basketToken;
  } catch (e) {
    console.error('Basket token failed to decode', e);
    removeBasketStorage();
    return null;
  }
};
