import { loginConstants } from '../constants/login.constants';

export const postCreateAccountRequest = (newAccount: object) => ({
  type: loginConstants.POST_CREATE_ACCOUNT_REQUEST,
  newAccount: newAccount,
});

export const postCreateAccountSuccess = (dataToken: object) => ({
  type: loginConstants.POST_CREATE_ACCOUNT_SUCCESS,
  dataToken,
});

export const postCreateAccountFailure = (error: object) => ({
  type: loginConstants.POST_CREATE_ACCOUNT_FAILURE,
  error,
});

export const postConnectAccountRequest = (credentials: object) => ({
  type: loginConstants.POST_CONNECT_ACCOUNT_REQUEST,
  credentials: credentials,
});

export const postConnectAccountSuccess = (dataToken: object) => ({
  type: loginConstants.POST_CONNECT_ACCOUNT_SUCCESS,
  dataToken,
});

export const postConnectAccountFailure = (error: object) => ({
  type: loginConstants.POST_CONNECT_ACCOUNT_FAILURE,
  error,
});

export const postConnectAppleAccountRequest = (credentials: object) => ({
  type: loginConstants.POST_CONNECT_APPLE_ACCOUNT_REQUEST,
  credentials: credentials,
});

export const postConnectAppleAccountSuccess = (dataToken: object) => ({
  type: loginConstants.POST_CONNECT_APPLE_ACCOUNT_SUCCESS,
  dataToken,
});

export const postConnectAppleAccountFailure = (error: object) => ({
  type: loginConstants.POST_CONNECT_APPLE_ACCOUNT_FAILURE,
  error,
});

export const postConnectGoogleAccountRequest = (credentials: object) => ({
  type: loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_REQUEST,
  credentials: credentials,
});

export const postConnectGoogleAccountSuccess = (dataToken: object) => ({
  type: loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_SUCCESS,
  dataToken,
});

export const postConnectGoogleAccountFailure = (error: object) => ({
  type: loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_FAILURE,
  error,
});

export const postConnectFacebookAccountRequest = (credentials: object) => ({
  type: loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_REQUEST,
  credentials: credentials,
});

export const postConnectFacebookAccountSuccess = (dataToken: object) => ({
  type: loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_SUCCESS,
  dataToken,
});

export const postConnectFacebookAccountFailure = (error: object) => ({
  type: loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_FAILURE,
  error,
});
