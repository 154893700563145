import ProductModel from './ProductModel';

export default class CategoryModel {
  id: number;
  description: string;
  name: string;
  products: ProductModel[] | undefined;

  constructor(data: any) {
    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
    this.products = data.products?.map(
      (product: any) => new ProductModel(product)
    );
  }
}
