import intl from 'react-intl-universal';
import AppLocale from '../lngProvider';
import { updateValidateFromMessage } from './validateFormMessage';
import { initGenderTranslation } from './Gender';

function initTranslations(newLocale?: string) {
  let currentLocale = Object.keys(AppLocale)[0];

  if (newLocale) {
    if (Object.prototype.hasOwnProperty.call(AppLocale, newLocale)) {
      currentLocale = newLocale;
    } else {
      const lang = newLocale.split(/[-_]/)[0];
      if (Object.prototype.hasOwnProperty.call(AppLocale, lang)) {
        currentLocale = lang;
      }
    }
  }

  document.documentElement.lang = currentLocale;

  intl
    .init({
      currentLocale: currentLocale,
      locales: AppLocale,
      warningHandler: (message) => {
        console.error(message);
      },
    })
    .then(() => {
      updateValidateFromMessage();
      initGenderTranslation();
    });
}

export default initTranslations;
