import React from 'react';
import './Title.css';

interface TitleProps {
  value: string | React.ReactNode;
  children?: React.ReactNode;
}
const Title = ({ value, children }: TitleProps) => (
  <div className="title-container">
    <div className="title">
      <h3>{value}</h3>
      {children}
    </div>
    <hr />
  </div>
);

export default Title;
