import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { placementConstants } from '../constants/placement.constants';
import {
  getPlacementListFailure,
  getPlacementListSuccess,
} from '../actions/placement.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getSectionsFetch(arg: any) {
  const url = shopConfig.router.productOptionPlacementListUrl;
  return await get(getUrl(url, arg));
}

export function* fetchGetPlacementList(action: any) {
  try {
    const placements = yield call(getSectionsFetch, action.params);
    yield put(getPlacementListSuccess(placements));
  } catch (error) {
    yield put(getPlacementListFailure(error));
  }
}

export function* getPlacementList() {
  yield takeLatest(
    placementConstants.GET_PLACEMENT_LIST_REQUEST,
    fetchGetPlacementList
  );
}

export default function* placementSaga() {
  yield all([fork(getPlacementList)]);
}
