import ShopLocaleModel from './ShopLocaleModel';
import ShopAnalyticsModel from './ShopAnalyticsModel';
import ShopBrandingModel from './ShopBrandingModel';
import ShopProjectModel from './ShopProjectModel';
import ShopPlacementMapModel from './ShopPlacementMapModel';
import ShopSocialModel from './ShopSocialModel';
import ShopPosModel from './ShopPosModel';
import ShopAuthProviderModel from './ShopAuthProviderModel';
import ShopRouterModel from './ShopRouterModel';

export default class ShopConfigModel {
  analytics: ShopAnalyticsModel;
  authProvider: ShopAuthProviderModel;
  branding: ShopBrandingModel;
  locale: ShopLocaleModel;
  placementMap: ShopPlacementMapModel | null;
  pos: ShopPosModel;
  project: ShopProjectModel;
  router: ShopRouterModel;
  socialLinks: ShopSocialModel;

  constructor(data: any) {
    this.analytics = new ShopAnalyticsModel(data.analytics);
    this.authProvider = new ShopAuthProviderModel(data.authProvider);
    this.branding = new ShopBrandingModel(data.branding);
    this.locale = new ShopLocaleModel(data.locale);
    this.placementMap = data.placementMap
      ? new ShopPlacementMapModel(data.placementMap)
      : null;
    this.pos = new ShopPosModel(data.pos);
    this.project = new ShopProjectModel(data.project);
    this.router = new ShopRouterModel(data.router);
    this.socialLinks = new ShopSocialModel(data.socialLinks);
  }
}
