import AmountModel from './AmountModel';
import { paymentProviders } from './OrderOverview/OrderConfirmModel';
import PaymentMethodModel from './OrderOverview/PaymentMethodModel';
import { PaymentStatusConstant } from '../Constants/Constants';

export default class PaymentModel {
  id: number;
  signature: string;
  status: keyof typeof PaymentStatusConstant;
  createdAt: Date | null;
  amount: AmountModel;
  statementReference: string;
  orderId: string;
  provider: keyof typeof paymentProviders;
  paymentMethod: PaymentMethodModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.signature = data.signature;
    this.status = data.status;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    this.amount = new AmountModel(data.amount);
    this.statementReference = data.statementReference;
    this.orderId = data.orderId.toString();
    this.provider = data.provider;
    this.paymentMethod = data.paymentMethod
      ? new PaymentMethodModel(data.paymentMethod)
      : null;
  }
}
