import ProviderDataModel from '../ProviderDataModel';

export default class PaybeModel extends ProviderDataModel {
  redirectUrl: string;

  constructor(data: any) {
    super();
    this.redirectUrl = data?.redirectUrl;
  }
}
