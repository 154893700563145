export const loginConstants = {
  POST_CREATE_ACCOUNT_REQUEST: 'POST_CREATE_ACCOUNT_REQUEST',
  POST_CREATE_ACCOUNT_SUCCESS: 'POST_CREATE_ACCOUNT_SUCCESS',
  POST_CREATE_ACCOUNT_FAILURE: 'POST_CREATE_ACCOUNT_FAILURE',

  POST_CONNECT_ACCOUNT_REQUEST: 'POST_CONNECT_ACCOUNT_REQUEST',
  POST_CONNECT_ACCOUNT_SUCCESS: 'POST_CONNECT_ACCOUNT_SUCCESS',
  POST_CONNECT_ACCOUNT_FAILURE: 'POST_CONNECT_ACCOUNT_FAILURE',

  POST_CONNECT_APPLE_ACCOUNT_REQUEST: 'POST_CONNECT_APPLE_ACCOUNT_REQUEST',
  POST_CONNECT_APPLE_ACCOUNT_SUCCESS: 'POST_CONNECT_APPLE_ACCOUNT_SUCCESS',
  POST_CONNECT_APPLE_ACCOUNT_FAILURE: 'POST_CONNECT_APPLE_ACCOUNT_FAILURE',

  POST_CONNECT_GOOGLE_ACCOUNT_REQUEST: 'POST_CONNECT_GOOGLE_ACCOUNT_REQUEST',
  POST_CONNECT_GOOGLE_ACCOUNT_SUCCESS: 'POST_CONNECT_GOOGLE_ACCOUNT_SUCCESS',
  POST_CONNECT_GOOGLE_ACCOUNT_FAILURE: 'POST_CONNECT_GOOGLE_ACCOUNT_FAILURE',

  POST_CONNECT_FACEBOOK_ACCOUNT_REQUEST:
    'POST_CONNECT_FACEBOOK_ACCOUNT_REQUEST',
  POST_CONNECT_FACEBOOK_ACCOUNT_SUCCESS:
    'POST_CONNECT_FACEBOOK_ACCOUNT_SUCCESS',
  POST_CONNECT_FACEBOOK_ACCOUNT_FAILURE:
    'POST_CONNECT_FACEBOOK_ACCOUNT_FAILURE',
};
