import { removeBaseUrl } from './parseUrl';
import { formatToFilterDate } from './formatDate';
import PrivateShopModel from '../Models/PrivateShopModel';
import { shopConfig } from '../Globals/ShopConfig';
import LastVisitedPageModel from '../Models/LastVisitedPageModel';

/*-----------------------Previous path--------------------------*/
export const addPreviousPathStorage = () => {
  const location = window.location;
  const fullPath = location.pathname + location.search + location.hash;
  const path = removeBaseUrl(fullPath);
  if (!path.includes('auth')) {
    sessionStorage.setItem('previous_path', path);
  }
};

export const getPreviousPath = () => {
  return sessionStorage.getItem('previous_path') ?? '/';
};

export const removePreviousPath = () => {
  return sessionStorage.removeItem('previous_path');
};

/*-----------------------Private shopToken--------------------------*/

export const addShopTokenToStorage = (shopToken: PrivateShopModel) => {
  localStorage.setItem('shopToken', JSON.stringify(shopToken));
};

export const getShopTokenStorage = (): string | null => {
  const token = fetchShopToken();
  return token?.shopToken;
};

export const getNewBasketToken = (): string | null => {
  const token = fetchShopToken();
  return token?.newBasketToken;
};

function fetchShopToken(): PrivateShopModel | null {
  if (!shopConfig) {
    // impossible to validate token if shopConfig is not loaded.
    return null;
  }
  const jsonToken = localStorage.getItem('shopToken');
  if (!jsonToken) {
    return null;
  }
  return decodeShopToken(jsonToken);
}

function decodeShopToken(jsonToken: string): PrivateShopModel | null {
  try {
    const shopToken: PrivateShopModel = JSON.parse(jsonToken);
    if (typeof shopToken !== 'object') {
      console.warn('Private shop token is not an object');
      deleteShopTokenStorage();
      return null;
    }
    const now = new Date();
    if (shopToken.shopTokenExpire >= now) {
      console.warn('Private shop token is expired');
      deleteShopTokenStorage();
      return null;
    }
    if (
      shopToken.project !== shopConfig.project.name ||
      shopToken.pos !== shopConfig.pos.slug
    ) {
      return null;
    }

    return shopToken;
  } catch (e) {
    console.error('Private shop token failed to decode', e);
    deleteShopTokenStorage();
    return null;
  }
}

function deleteShopTokenStorage() {
  localStorage.removeItem('shopToken');
}

/*-----------------------Filter date--------------------------*/

export const setFilterDateStorage = (date: Date | null) => {
  localStorage.setItem('filterDate', formatToFilterDate(date));
};

export const getFilterDateStorage = (): Date | null => {
  const dateString = localStorage.getItem('filterDate');
  let date;

  if (!dateString) {
    return null;
  }

  if (!dateString.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    return null;
  }

  try {
    date = new Date(dateString + 'T13:00');
  } catch (e) {
    return null;
  }

  return date;
};

export const removeFilterDateStorage = () => {
  localStorage.removeItem('filterDate');
};

/*-----------------------Basket last visited item--------------------------*/

export const setLastVisitedItemStorage = (item) => {
  sessionStorage.setItem('lastVisitedItem', JSON.stringify(item));
};

export const getLastVisitedItemStorage = (): LastVisitedPageModel | null => {
  const item = sessionStorage.getItem('lastVisitedItem');
  return item ? (JSON.parse(item) as LastVisitedPageModel) : null;
};

export const removeLastVisitedItemStorage = () => {
  sessionStorage.removeItem('lastVisitedItem');
};

/*-----------------------Remember me--------------------------*/

export const setRememberMe = (value: boolean): void => {
  return localStorage.setItem('remember_me', value.toString());
};

export const getRememberMe = (): boolean => {
  const value = localStorage.getItem('remember_me');
  return value === 'true';
};
