import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { upsellingConstants } from '../constants/upselling.constants';
import {
  getUpsellingSuccess,
  getUpsellingFailure,
} from '../actions/upselling.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';
import getBasketSession from '../../services/basketSession';

export async function getUpsellingFetch(arg) {
  const url = shopConfig.router.basketUpsellingUrl;
  return await get(getUrl(url, arg.basketSession));
}

export function* fetchGetUpselling() {
  try {
    const upselling = yield call(getUpsellingFetch, {
      basketSession: getBasketSession(),
    });
    yield put(getUpsellingSuccess(upselling));
  } catch (error) {
    yield put(getUpsellingFailure(error));
  }
}

export function* getUpselling() {
  yield takeLatest(upsellingConstants.GET_UPSELLING_REQUEST, fetchGetUpselling);
}

export default function* upsellingSaga() {
  yield all([fork(getUpselling)]);
}
