import ProviderDataModel from '../ProviderDataModel';

export default class VivaWalletModel extends ProviderDataModel {
  redirectUrl: string;

  constructor(data: any) {
    super();
    this.redirectUrl = data.redirectUrl;
  }
}
