import { datesConstants } from '../constants/dates.constants';

const initialState = {
  datesList: [],
};

const dates = (state = initialState, action: any) => {
  switch (action.type) {
    case datesConstants.GET_DATES_LIST_REQUEST: {
      return {
        ...state,
        datesList: { dates: [], error: false, loading: true },
      };
    }

    case datesConstants.GET_DATES_LIST_SUCCESS: {
      return {
        ...state,
        datesList: { dates: action.dates, error: false, loading: false },
      };
    }

    case datesConstants.GET_DATES_LIST_FAILURE: {
      return {
        ...state,
        datesList: { dates: null, error: true, loading: false },
      };
    }
    default:
      return state;
  }
};

export default dates;
