import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { loginConstants } from '../constants/login.constants';
import {
  postCreateAccountSuccess,
  postCreateAccountFailure,
  postConnectAccountSuccess,
  postConnectAccountFailure,
  postConnectAppleAccountSuccess,
  postConnectAppleAccountFailure,
  postConnectGoogleAccountSuccess,
  postConnectGoogleAccountFailure,
  postConnectFacebookAccountSuccess,
  postConnectFacebookAccountFailure,
} from '../actions/login.actions';
import { post } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function postCreateAccountFetch(arg) {
  const url = shopConfig.router.authRegisterUrl;
  return await post(url, arg.newAccount);
}

export async function postConnectAccountFetch(arg) {
  const url = shopConfig.router.authLoginUrl;
  return await post(url, arg);
}

export async function postConnectAppleAccountFetch(arg) {
  const url = shopConfig.router.authAppleConnectCallbackUrl;
  return await post(url, arg);
}

export async function postConnectGoogleAccountFetch(arg) {
  const url = shopConfig.router.authGoogleConnectCallbackUrl;
  return await post(url, arg);
}

export async function postConnectFacebookAccountFetch(arg) {
  const url = shopConfig.router.authFacebookConnectCallbackUrl;
  return await post(url, arg);
}

export function* fetchPostCreateAccount(action) {
  try {
    const dataToken = yield call(postCreateAccountFetch, {
      newAccount: action.newAccount,
    });
    yield put(postCreateAccountSuccess(dataToken));
  } catch (error) {
    yield put(postCreateAccountFailure(error));
  }
}

export function* fetchPostConnectAccount(action) {
  try {
    const dataToken = yield call(postConnectAccountFetch, {
      email: action.credentials.email,
      password: action.credentials.password,
    });
    yield put(postConnectAccountSuccess(dataToken));
  } catch (error) {
    yield put(postConnectAccountFailure(error));
  }
}

export function* fetchPostConnectAppleAccount(action) {
  try {
    const dataToken = yield call(postConnectAppleAccountFetch, {
      id_code: action.credentials.id,
      access_token: action.credentials.accessToken,
      firstname: action.credentials.firstname,
      lastname: action.credentials.lastname,
      redirect_uri: window.location,
    });
    yield put(postConnectAppleAccountSuccess(dataToken));
  } catch (error) {
    yield put(postConnectAppleAccountFailure(error));
  }
}

export function* fetchPostConnectGoogleAccount(action) {
  try {
    const dataToken = yield call(postConnectGoogleAccountFetch, {
      gplusId: action.credentials.id,
      gplusAccessToken: action.credentials.accessToken,
      firstname: action.credentials.firstname,
      lastname: action.credentials.lastname,
    });
    yield put(postConnectGoogleAccountSuccess(dataToken));
  } catch (error) {
    yield put(postConnectGoogleAccountFailure(error));
  }
}

export function* fetchPostConnectFacebookAccount(action) {
  try {
    const dataToken = yield call(postConnectFacebookAccountFetch, {
      facebookId: action.credentials.id,
      fbAccessToken: action.credentials.accessToken,
      firstname: action.credentials.firstname,
      lastname: action.credentials.lastname,
    });
    yield put(postConnectFacebookAccountSuccess(dataToken));
  } catch (error) {
    yield put(postConnectFacebookAccountFailure(error));
  }
}

export function* postCreateAccount() {
  yield takeLatest(
    loginConstants.POST_CREATE_ACCOUNT_REQUEST,
    fetchPostCreateAccount
  );
}

export function* postConnectAccount() {
  yield takeLatest(
    loginConstants.POST_CONNECT_ACCOUNT_REQUEST,
    fetchPostConnectAccount
  );
}
export function* postConnectAppleAccount() {
  yield takeLatest(
    loginConstants.POST_CONNECT_APPLE_ACCOUNT_REQUEST,
    fetchPostConnectAppleAccount
  );
}

export function* postConnectGoogleAccount() {
  yield takeLatest(
    loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_REQUEST,
    fetchPostConnectGoogleAccount
  );
}

export function* postConnectFacebookAccount() {
  yield takeLatest(
    loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_REQUEST,
    fetchPostConnectFacebookAccount
  );
}

export default function* loginSaga() {
  yield all([
    fork(postCreateAccount),
    fork(postConnectAccount),
    fork(postConnectAppleAccount),
    fork(postConnectGoogleAccount),
    fork(postConnectFacebookAccount),
  ]);
}
