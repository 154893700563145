interface ShopSocialModelProps {
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  websiteUrl: string | null;
}

export default class ShopSocialModel {
  projectHaveLinks: boolean;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  websiteUrl: string | null;

  constructor(data: ShopSocialModelProps) {
    this.projectHaveLinks = this.haveProjectMinOneLink(data);
    this.facebookUrl = data.facebookUrl;
    this.twitterUrl = data.twitterUrl;
    this.instagramUrl = data.instagramUrl;
    this.websiteUrl = data.websiteUrl;
  }

  private haveProjectMinOneLink(links: ShopSocialModelProps): boolean {
    return Object.values(links).some((link) => link !== null && link !== '');
  }
}
