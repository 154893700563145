import { cmsConstants } from '../constants/cms.constants';

export const getCmsContentRequest = (cmsUrl: string) => ({
  type: cmsConstants.GET_CMS_CONTENT_REQUEST,
  cmsUrl: cmsUrl,
});

export const getCmsContentSuccess = (cmsContent: object) => ({
  type: cmsConstants.GET_CMS_CONTENT_SUCCESS,
  cmsContent,
});

export const getCmsContentFailure = (error: object) => ({
  type: cmsConstants.GET_CMS_CONTENT_FAILURE,
  error,
});
