import EnMessages from './locales/en/en.json';
import FrMessages from './locales/fr/fr.json';
import DeMessages from './locales/de/de.json';
import NlMessages from './locales/nl/nl.json';
import En_CaMessages from './locales/en_CA/en_CA.json';
import Fr_CaMessages from './locales/fr_CA/fr_CA.json';

const AppLocale = {
  en: EnMessages,
  fr: FrMessages,
  de: DeMessages,
  nl: NlMessages,
  en_CA: En_CaMessages,
  fr_CA: Fr_CaMessages,
};

export default AppLocale;
