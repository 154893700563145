import ProviderDataModel from '../ProviderDataModel';

export default class PayconiqModel extends ProviderDataModel {
  checkStatusUrl: string;
  deepLinkUrl: string;
  qrCodeUrl: string;

  constructor(data: any) {
    super();
    if (!data) {
      return;
    }
    this.checkStatusUrl = data.checkStatuskUrl;
    this.deepLinkUrl = data.deepLinkUrl;
    this.qrCodeUrl = data.qrCodeUrl;
  }
}
