import { getRememberMe } from './handleStorage';
const storageKey = 'loginToken';

export const addTokenToStorage = (data: {
  token: string;
  refresh_token: string;
}) => {
  const date = new Date();
  const nextMonth = date.setMonth(date.getMonth() + 1);
  const nextMonthDate = new Date(nextMonth);

  const storageValue = JSON.stringify({
    expires: nextMonthDate,
    token: data.token,
    refreshToken: data.refresh_token,
  });

  if (getRememberMe()) {
    localStorage.setItem(storageKey, storageValue);
  } else {
    sessionStorage.setItem(storageKey, storageValue);
  }
};

export const removeTokenToStorage = () => {
  localStorage.removeItem(storageKey);
  sessionStorage.removeItem(storageKey);
};

export const getTokenStorage = () => {
  return getStoreToken()?.token;
};

export const getRefreshTokenStorage = () => {
  return getStoreToken()?.refreshToken;
};

export const existTokensInStorage = () => {
  const token = getTokenStorage();
  const refreshToken = getRefreshTokenStorage();

  return token && refreshToken;
};

function decodeLoginToken(jsonToken: string) {
  try {
    const loginToken = JSON.parse(jsonToken);
    if (typeof loginToken !== 'object') {
      removeTokenToStorage();
      return null;
    }
    const now = new Date();
    if (loginToken.expires >= now) {
      removeTokenToStorage();
      return null;
    }

    return loginToken;
  } catch (e) {
    console.error('Login token failed to decode', e);
    removeTokenToStorage();
    return null;
  }
}

function getStoreToken() {
  let jsonToken = '';
  if (localStorage.getItem(storageKey)) {
    jsonToken = localStorage.getItem(storageKey);
  } else {
    jsonToken = sessionStorage.getItem(storageKey);
  }

  if (!jsonToken) {
    return null;
  }
  return decodeLoginToken(jsonToken);
}
