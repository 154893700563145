export default class OrderPaymentItemModel {
  id: number;
  name: string;
  price: number | null;
  quantity: number;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.priceInclTax?.amount ?? null;
    this.quantity = data.quantity;
  }
}
