import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatchViewPage } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';

export interface PageProps {
  translation?: string;
  children: React.ReactElement;
}

const Page = ({ translation, children }: PageProps): React.ReactElement => {
  const reactLocation = useLocation();

  useEffect(() => {
    document.title = translation
      ? Translator.trans(translation) + ' - ' + shopConfig.pos.shopName
      : shopConfig.pos.shopName;

    const newUrl =
      reactLocation.pathname + reactLocation.search + reactLocation.hash;

    dispatchViewPage(newUrl);
  }, [
    reactLocation.hash,
    reactLocation.pathname,
    reactLocation.search,
    translation,
  ]);

  return children;
};

export default Page;
