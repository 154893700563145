import ProviderDataModel from '../ProviderDataModel';

export default class SquareUpModel extends ProviderDataModel {
  applicationId: string;
  locationId: string;

  constructor(data: any) {
    super();
    this.applicationId = data.applicationId;
    this.locationId = data.locationId;
  }
}
