export default class UpsellingModel {
  attendeeForm: any;
  availableQuantity: number | null;
  barcodeValue: string;
  canonicalName: string;
  cashlessValue: number | null;
  cashlessValues: {
    [id: number]: {
      free: number;
      paid: number;
      projectWallet: { id: number };
      total: number;
    };
  };
  fees: number;
  id: number;
  isAvailableForRefund: boolean;
  isHighlight: boolean;
  isNfcTagRequired: boolean;
  isOnSite: boolean;
  isOnline: boolean;
  isPrintAtHome: boolean;
  isSoldOut: boolean;
  isStockManaged: boolean;
  marketingName: string;
  name: string;
  noGroupingInSale: boolean;
  options: boolean;
  platform: number;
  price: number;
  priceWithVat: number;
  priceWithoutVat: number;
  printTicket: boolean;
  productType: { id: number };
  quantityIncrement: number;
  quantityMaxPerSale: number | null;
  quantityMinPerSale: number | null;
  quantityStockMaximumTotalCustomer: number | null;
  saleStatus: string;
  sessions: boolean;
  vatRate: { id: number };

  constructor(data: any) {
    this.attendeeForm = data.attendeeForm;
    this.availableQuantity = data.availableQuantity;
    this.barcodeValue = data.barcodeValue;
    this.canonicalName = data.canonicalName;
    this.cashlessValue = data.cashlessValue;
    this.cashlessValues = data.cashlessValues;
    this.fees = data.fees;
    this.id = data.id;
    this.isAvailableForRefund = data.isAvailableForRefund;
    this.isHighlight = data.isHighlight;
    this.isNfcTagRequired = data.isNfcTagRequired;
    this.isOnSite = data.isOnSite;
    this.isOnline = data.isOnline;
    this.isPrintAtHome = data.isPrintAtHome;
    this.isSoldOut = data.isSoldOut;
    this.isStockManaged = data.isStockManaged;
    this.marketingName = data.marketingName;
    this.name = data.name;
    this.noGroupingInSale = data.noGroupingInSale;
    this.options = data.options;
    this.platform = data.platform;
    this.price = data.price;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.printTicket = data.printTicket;
    this.productType = data.productType;
    this.quantityIncrement = data.quantityIncrement ?? 1;
    this.quantityMaxPerSale = data.quantityMaxPerSale;
    this.quantityMinPerSale = data.quantityMinPerSale;
    this.quantityStockMaximumTotalCustomer =
      data.quantityStockMaximumTotalCustomer;
    this.saleStatus = data.saleStatus;
    this.sessions = data.sessions;
    this.vatRate = data.vatRate;
  }
}
