import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { paymentMethodConstants } from '../constants/paymentMethod.constants';
import {
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
} from '../actions/paymentMethod.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getPaymentMethodFetch() {
  const url = shopConfig.router.paymentmethodListUrl;
  return await get(getUrl(url));
}

export function* fetchGetPaymentMethod() {
  try {
    const paymentMethod = yield call(getPaymentMethodFetch);
    yield put(getPaymentMethodSuccess(paymentMethod));
  } catch (error) {
    yield put(getPaymentMethodFailure(error));
  }
}

export function* getPaymentMethod() {
  yield takeLatest(
    paymentMethodConstants.GET_PAYMENTMETHOD_REQUEST,
    fetchGetPaymentMethod
  );
}

export default function* paymentmethodSaga() {
  yield all([fork(getPaymentMethod)]);
}
