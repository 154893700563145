import {
  addTokenToStorage,
  getRefreshTokenStorage,
  getTokenStorage,
  removeTokenToStorage,
} from './handleTokens';
import { getUrl, post } from './httpService';

const parseJwt = (token: string) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isTokenNearlyInvalid = (token: string) => {
  return !isTokenValid(token, 5);
};

export const isTokenOver = (token: string) => {
  return !isTokenValid(token);
};

export const isTokenValid = (token: string, time = 0) => {
  let isValid = false;

  if (token) {
    const decodedJwt = parseJwt(token);

    const jwtExpireDate = decodedJwt.exp * 1000;
    const delay = time * 60 * 1000;
    const nowDate = Date.now();

    isValid = nowDate < jwtExpireDate - delay;
  }

  return isValid;
};

export function ensureValidToken() {
  const token = getTokenStorage();

  if (!isTokenOver(token)) {
    return Promise.resolve();
  }

  const refreshToken = getRefreshTokenStorage();
  if (!refreshToken) {
    return Promise.resolve();
  }

  return post(
    getUrl('/api/refresh_token'),
    { refresh_token: refreshToken },
    { no401Redirect: true }
  )
    .then((response) => {
      addTokenToStorage(response);
    })
    .catch((err) => {
      console.error(err);
      removeTokenToStorage();
    });
}
