import { sessionsConstants } from '../constants/sessions.constants';

export const getSessionsRequest = (options: object) => ({
  type: sessionsConstants.GET_SESSIONS_REQUEST,
  options,
});

export const getSessionsSuccess = (sessions: object) => ({
  type: sessionsConstants.GET_SESSIONS_SUCCESS,
  sessions,
});

export const getSessionsFailure = (error: object) => ({
  type: sessionsConstants.GET_SESSIONS_FAILURE,
  error,
});
