import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import EmisysButton from '../EmisysButton/EmisysButton';
import Translator from '../../services/translator';
import ModalEmisys from './ModalEmisys';
import './Modal.css';

interface ModalFilterDateProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ModalTimer = (props: ModalFilterDateProps) => (
  <ModalEmisys
    title={null}
    closable={false}
    open={props.isOpen}
    onCancel={props.handleClose}
    footer={null}
    centered
  >
    <Button
      type="primary"
      shape="circle"
      className="close-button"
      icon={<CloseOutlined />}
      onClick={props.handleClose}
    />

    <div className="modalTimer">
      <h2>{Translator.trans('timer.expired')}</h2>

      <p>{Translator.trans('timer.expired.desc')}</p>

      <EmisysButton
        className={'modal-timer-button'}
        onClick={props.handleClose}
      >
        {Translator.trans('order.button.back_to_products')}
      </EmisysButton>
    </div>
  </ModalEmisys>
);

export default ModalTimer;
