export default class ShopAuthProviderModel {
  googleAppId: string;
  facebookAppId: string;
  appleServiceId: string;

  constructor(data: any) {
    this.googleAppId = data.googleAppId;
    this.facebookAppId = data.facebookAppId;
    this.appleServiceId = data.appleServiceId;
  }
}
