import { BasketStatusConstant } from '../Constants/Constants';
import AmountModel from './AmountModel';
import UserModel from './UserModel';
import AddressModel from './AddressModel';
import OrderItemModel from './OrderItemModel';
import PaymentModel from './PaymentModel';

export default class OrderModel {
  id: number;
  amount: AmountModel;
  amountExclVat: AmountModel;
  vatAmount: AmountModel;
  confirmedAt: Date;
  signature: string;
  totalItems: number;
  items: OrderItemModel[];
  customer: UserModel | null;
  billingAddress: AddressModel | null;
  status: keyof typeof BasketStatusConstant;
  payments: PaymentModel[];

  constructor(data: any) {
    this.id = data.id;
    this.amount = new AmountModel(data.amount);
    this.amountExclVat = new AmountModel(data.amountExclVat);
    this.vatAmount = new AmountModel(data.vatAmount);
    this.confirmedAt = data.confirmedAt ? new Date(data.confirmedAt) : null;
    this.customer = data.customer ? new UserModel(data.customer) : null;
    this.billingAddress = data.customer?.billingAddress
      ? new AddressModel(data.customer.billingAddress)
      : null;
    this.items = data.items.map((item) => new OrderItemModel(item));
    this.signature = data.signature;
    this.status = data.status;
    this.totalItems = data.totalItems;
    this.payments = data.payments.map((payment) => new PaymentModel(payment));
  }
}
