import React from 'react';
import ItemModel from '../../Models/ItemModel';
import BasketAccomItem from './Items/BasketAccomItem';
import BasketFormuleItem from './Items/BasketFormuleItem';

export interface basketItemProps {
  item: ItemModel;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  editable?: boolean;
}

const DisplayBasketItems = (props: basketItemProps) => {
  if (props.item.reservation) {
    return <BasketAccomItem {...props} />;
  } else {
    return <BasketFormuleItem {...props} />;
  }
};

export default DisplayBasketItems;
