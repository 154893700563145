export const basketConstants = {
  GET_BASKET_REQUEST: 'GET_BASKET_REQUEST',
  GET_BASKET_SUCCESS: 'GET_BASKET_SUCCESS',
  GET_BASKET_FAILURE: 'GET_BASKET_FAILURE',

  POST_BASKET_ITEM_REQUEST: 'POST_BASKET_ITEM_REQUEST',
  POST_BASKET_ITEM_SUCCESS: 'POST_BASKET_ITEM_SUCCESS',
  POST_BASKET_ITEM_FAILURE: 'POST_BASKET_ITEM_FAILURE',

  POST_BASKET_MULTIPLE_ITEM_REQUEST: 'POST_BASKET_MULTIPLE_ITEM_REQUEST',
  POST_BASKET_MULTIPLE_ITEM_SUCCESS: 'POST_BASKET_MULTIPLE_ITEM_SUCCESS',
  POST_BASKET_MULTIPLE_ITEM_FAILURE: 'POST_BASKET_MULTIPLE_ITEM_FAILURE',

  PUT_BASKET_ITEM_REQUEST: 'PUT_BASKET_ITEM_REQUEST',
  PUT_BASKET_ITEM_SUCCESS: 'PUT_BASKET_ITEM_SUCCESS',
  PUT_BASKET_ITEM_FAILURE: 'PUT_BASKET_ITEM_FAILURE',

  DELETE_ALL_BASKET_REQUEST: 'DELETE_ALL_BASKET_REQUEST',
  DELETE_ALL_BASKET_SUCCESS: 'DELETE_ALL_BASKET_SUCCESS',
  DELETE_ALL_BASKET_FAILURE: 'DELETE_ALL_BASKET_FAILURE',

  POST_BASKET_ACCOMMODATION_REQUEST: 'POST_BASKET_ACCOMMODATION_REQUEST',
  POST_BASKET_ACCOMMODATION_SUCCESS: 'POST_BASKET_ACCOMMODATION_SUCCESS',
  POST_BASKET_ACCOMMODATION_FAILURE: 'POST_BASKET_ACCOMMODATION_FAILURE',

  POST_BASKET_DISCOUNT_REQUEST: 'POST_BASKET_DISCOUNT_REQUEST',
  POST_BASKET_DISCOUNT_SUCCESS: 'POST_BASKET_DISCOUNT_SUCCESS',
  POST_BASKET_DISCOUNT_FAILURE: 'POST_BASKET_DISCOUNT_FAILURE',
};
