import { shopConfig } from '../Globals/ShopConfig';

function getLocaleFixup() {
  let tempLocale = shopConfig.locale.locale.replace('-', '_');
  /*
   * Not every locale exists on disk. Map missing locale to similar locales.
   */
  const localeMap: { [key: string]: string } = {
    en_CA: 'en_US',
    fr_CH: 'fr_BE',
  };

  if (localeMap.hasOwnProperty(tempLocale)) {
    tempLocale = localeMap[tempLocale];
  }
  return tempLocale;
}

export function getAntdLocale() {
  const currentLocale = getLocaleFixup();
  let antdLocale;
  try {
    antdLocale = require('antd/lib/locale/' + currentLocale + '.js').default;
  } catch (error) {
    let baseLocale = currentLocale.slice(0, 2);
    let standardLocale;

    /*
     * Map languages to locales available in antd.
     * By default, some languages such as fr or de are easily mapped to fr_FR or de_DE but other languages have no default locale, such as en_EN. Those languages must be listed in the table below with the most probable locale.
     */
    const antdMap: { [key: string]: string } = {
      en: 'en_US',
      hy: 'hy_MA',
    };

    if (antdMap.hasOwnProperty(baseLocale)) {
      standardLocale = antdMap[baseLocale];
    } else {
      standardLocale =
        baseLocale.toLowerCase() + '_' + baseLocale.toUpperCase();
    }

    try {
      antdLocale = require('antd/lib/locale/' + standardLocale + '.js').default;
    } catch (error) {
      antdLocale = require('antd/lib/locale/en_US.js').default;
    }
  }

  return antdLocale;
}
