import React from 'react';
import { Col } from 'antd';
import BasketRecap from '../../../components/BasketRecap/BasketRecap';
import Upselling from '../../../components/Upselling/Upselling';
import BasketSummary from '../../../components/BasketSummary/BasketSummary';
import '../index.css';

interface BasketReviewProps {
  updateBasketReview: () => void;
  goToNextStep: () => void;
}

const BasketReview = (props: BasketReviewProps) => (
  <>
    <Col className="gutter-row" xs={24} sm={16}>
      <BasketRecap editable={true} />
      <Upselling />
    </Col>
    <Col className="gutter-row" xs={24} sm={8}>
      <BasketSummary
        goToNextStep={props.goToNextStep}
        updateBasketReview={props.updateBasketReview}
      />
    </Col>
  </>
);

export default BasketReview;
