import { ticketsConstants } from '../constants/tickets.constants';

export const getTicketsRequest = (options: object) => ({
  type: ticketsConstants.GET_TICKETS_REQUEST,
  options,
});

export const getTicketsSuccess = (tickets: object) => ({
  type: ticketsConstants.GET_TICKETS_SUCCESS,
  tickets,
});

export const getTicketsFailure = (error: object) => ({
  type: ticketsConstants.GET_TICKETS_FAILURE,
  error,
});

export const getTicketDetailsRequest = (ticketId: number) => ({
  type: ticketsConstants.GET_TICKET_DETAILS_REQUEST,
  ticketId,
});

export const getTicketDetailsSuccess = (ticket: object) => ({
  type: ticketsConstants.GET_TICKET_DETAILS_SUCCESS,
  ticket,
});

export const getTicketDetailsFailure = (error: object) => ({
  type: ticketsConstants.GET_TICKET_DETAILS_FAILURE,
  error,
});
