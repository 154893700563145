import { optionsConstants } from '../constants/options.constants';
import SessionOptionModel from '../../Models/SessionOptionModel';
import OptionRenderingModel from '../../Models/OptionRenderingModel';

const initialState = {
  optionsList: '',
  sessionOptionsList: null,
};

const options = (state = initialState, action: any) => {
  switch (action.type) {
    case optionsConstants.GET_OPTIONS_REQUEST: {
      return {
        ...state,
        optionsList: { options: [], error: false, loading: true },
      };
    }

    case optionsConstants.GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        optionsList: {
          options: new OptionRenderingModel(action.options),
          error: false,
          loading: false,
        },
      };
    }

    case optionsConstants.GET_OPTIONS_FAILURE: {
      return {
        ...state,
        optionsList: { options: null, error: true, loading: false },
      };
    }

    case optionsConstants.GET_SESSION_OPTIONS_REQUEST: {
      return {
        ...state,
        sessionOptionsList: {
          options: null,
          error: false,
          loading: true,
        },
      };
    }

    case optionsConstants.GET_SESSION_OPTIONS_SUCCESS: {
      const currentOptionsList = SessionOptionModel.setSessionOptionModel(
        action.options
      );
      return {
        ...state,
        sessionOptionsList: {
          options: currentOptionsList,
          session_id: action.session_id,
          error: false,
          loading: false,
        },
      };
    }

    case optionsConstants.GET_SESSION_OPTIONS_FAILURE: {
      return {
        ...state,
        sessionOptionsList: { options: null, error: true, loading: false },
      };
    }
    default:
      return state;
  }
};

export default options;
