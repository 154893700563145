import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { accommodationConstants } from '../constants/accommodation.constants';
import {
  getOccupantTypesSuccess,
  getOccupantTypesFailure,
  getAccommodationListSuccess,
  getAccommodationListFailure,
  getAccommodationAssembliesSuccess,
  getAccommodationAssembliesFailure,
  getAccommodationDetailSuccess,
  getAccommodationDetailsFailure,
} from '../actions/accommodation.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getOccupantTypesFetch() {
  const url = shopConfig.router.accommodationOccupantTypeListUrl;
  return await get(getUrl(url));
}

export async function getAccommodationListFetch(arg) {
  const url = shopConfig.router.accommodationListUrl;
  return await get(getUrl(url, arg.params));
}

export async function getAccommodationAssembliesFetch(arg) {
  let url = shopConfig.router.accommodationAssemblyListUrl;
  const newUrl = url.replaceAll('__ID__', arg.accommodationId);
  return await get(getUrl(newUrl, arg.params));
}

export async function getAccommodationDetailFetch(arg) {
  let url = shopConfig.router.accommodationDetailUrl;
  const newUrl = url.replaceAll('__ID__', arg.accommodationId);
  return await get(getUrl(newUrl, arg.params));
}

export function* fetchGetOccupantTypes() {
  try {
    const occupantTypes = yield call(getOccupantTypesFetch);
    yield put(getOccupantTypesSuccess(occupantTypes));
  } catch (error) {
    yield put(getOccupantTypesFailure(error));
  }
}

export function* fetchGetAccommodationList(action) {
  try {
    const accommodationList = yield call(getAccommodationListFetch, {
      accommodationUrl: action.accommodationtUrl,
      params: action.params,
    });
    yield put(getAccommodationListSuccess(accommodationList));
  } catch (error) {
    yield put(getAccommodationListFailure(error));
  }
}

export function* fetchGetAccommodationAssemblies(action) {
  try {
    const accommodationList = yield call(getAccommodationAssembliesFetch, {
      accommodationId: action.accommodationId,
      params: action.params,
    });
    yield put(getAccommodationAssembliesSuccess(accommodationList));
  } catch (error) {
    yield put(getAccommodationAssembliesFailure(error));
  }
}

export function* fetchGetAccommodationDetail(action) {
  try {
    const accommodation = yield call(getAccommodationDetailFetch, {
      accommodationId: action.accommodationId,
      params: action.params,
    });
    yield put(getAccommodationDetailSuccess(accommodation));
  } catch (error) {
    yield put(getAccommodationDetailsFailure(error));
  }
}

export function* getOccupantTypes() {
  yield takeLatest(
    accommodationConstants.GET_OCCUPANT_TYPES_REQUEST,
    fetchGetOccupantTypes
  );
}

export function* getAccommodationList() {
  yield takeLatest(
    accommodationConstants.GET_ACCOMMODATION_LIST_REQUEST,
    fetchGetAccommodationList
  );
}

export function* getAccommodationAssemblies() {
  yield takeLatest(
    accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_REQUEST,
    fetchGetAccommodationAssemblies
  );
}

export function* getAccommodationDetail() {
  yield takeLatest(
    accommodationConstants.GET_ACCOMMODATION_DETAIL_REQUEST,
    fetchGetAccommodationDetail
  );
}

export default function* accommodationSaga() {
  yield all([
    fork(getOccupantTypes),
    fork(getAccommodationList),
    fork(getAccommodationAssemblies),
    fork(getAccommodationDetail),
  ]);
}
