import { attendeeConstants } from '../constants/attendee.constants';

export const getAttendeeFormRequest = () => ({
  type: attendeeConstants.GET_ATTENDEE_FORM_REQUEST,
});

export const getAttendeeFormSuccess = (form: object) => ({
  type: attendeeConstants.GET_ATTENDEE_FORM_SUCCESS,
  form,
});

export const getAttendeeFormFailure = (error: object) => ({
  type: attendeeConstants.GET_ATTENDEE_FORM_FAILURE,
  error,
});

export const postAttendeeFormRequest = (form: object) => ({
  type: attendeeConstants.POST_ATTENDEE_FORM_REQUEST,
  form,
});

export const postAttendeeFormSuccess = (response: object) => ({
  type: attendeeConstants.POST_ATTENDEE_FORM_SUCCESS,
  response,
});

export const postAttendeeFormFailure = (error: object) => ({
  type: attendeeConstants.POST_ATTENDEE_FORM_FAILURE,
  error,
});
