import { usersConstants } from '../constants/users.constants';

const initialState = {
  loggedUser: null,
};

const users = (state = initialState, action: any) => {
  switch (action.type) {
    case usersConstants.GET_LOGGED_USER_REQUEST: {
      return {
        ...state,
        loggedUser: { user: null, loading: true, error: false },
      };
    }

    case usersConstants.GET_LOGGED_USER_SUCCESS: {
      return {
        ...state,
        loggedUser: {
          user: action.logged,
          loading: false,
          error: false,
        },
      };
    }

    case usersConstants.GET_LOGGED_USER_FAILURE: {
      return {
        ...state,
        loggedUser: {
          user: null,
          loading: false,
          error: true,
          message: action.error,
        },
      };
    }

    case usersConstants.LOG_USER_OUT: {
      return {
        ...state,
        loggedUser: {
          user: null,
          loading: false,
          error: false,
        },
      };
    }

    default:
      return state;
  }
};

export default users;
