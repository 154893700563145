import React from 'react';
import { notification, Alert } from 'antd';
import Container from '../Container/Container';
import './Notification.css';

notification.config({
  maxCount: 1,
});

export function openNotificationSuccess(
  title: string | React.ReactElement,
  description: string | React.ReactElement
): void {
  notification['success']({
    message: title,
    description: description,
  });
}

export function openNotificationWarning(
  title: string | React.ReactElement,
  description: string | React.ReactElement
): void {
  notification['warning']({
    message: title,
    description: description,
  });
}

export function openNotificationError(
  title: string | React.ReactElement,
  description: string | React.ReactElement
): void {
  notification['error']({
    message: title,
    description: description,
  });
}

export function showMessage(
  title: string | React.ReactElement,
  description: string | React.ReactElement
): React.ReactElement {
  return (
    <Container className="message-container">
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
    </Container>
  );
}

export function showMessageSuccess(
  title: string | React.ReactElement,
  description: string | React.ReactElement,
  closable?: boolean
): React.ReactElement {
  return (
    <div className="alert-container">
      <Alert
        message={title}
        description={description}
        type="success"
        showIcon
        closable={closable}
      />
    </div>
  );
}

export function showMessageWarning(
  title: string | React.ReactElement,
  description: string | React.ReactElement,
  closable?: boolean
): React.ReactElement {
  return (
    <div className="alert-container">
      <Alert
        message={title}
        description={description}
        type="warning"
        showIcon
        closable={closable}
      />
    </div>
  );
}

export function showMessageError(
  title: string | React.ReactElement,
  description: string | React.ReactElement,
  closable?: boolean
): React.ReactElement {
  return (
    <div className="alert-container">
      <Alert
        message={title}
        description={description}
        type="error"
        showIcon
        closable={closable}
      />
    </div>
  );
}
