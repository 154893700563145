import React, { useCallback, useEffect, useRef, useState } from 'react';
import Quantity from '../../Quantity/Quantity';
import { putBasketItemRequest } from '../../../redux/actions/basket.actions';
import { DeleteOutlined } from '@ant-design/icons';
import SimplePrice from '../../Price/SimplePrice';
import RequiredProducts from '../../RequiredProducts/RequiredProducts';
import {
  dispatchRemoveToCart,
  dispatchUpdateItem,
} from '../../../services/tracking';
import { shopConfig, shopIsIframe } from '../../../Globals/ShopConfig';
import Translator from '../../../services/translator';
import ItemModel from '../../../Models/ItemModel';
import { basketItemProps } from '../DisplayBasketItems';
import { useDispatch } from 'react-redux';
import '../DisplayBasketItems.css';

const BasketFormuleItem = (props: basketItemProps) => {
  const dispatch = useDispatch();
  const timeOut = useRef(null);

  const [newQuantity, setNewQuantity] = useState(0);

  const updateBasket = useCallback(
    (id: number, qty: number) => {
      dispatch(
        putBasketItemRequest({
          itemId: id,
          quantity: qty,
          project: shopConfig.project.name,
          pos: shopConfig.pos.slug,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (props.item.quantity) {
      setNewQuantity(props.item.quantity);
    }
  }, [props.item.quantity]);

  const handleDeleteItem = (item: ItemModel) => {
    props.setLoading(true);
    updateBasket(item.id, 0);

    dispatchRemoveToCart(item.name, item.price);
  };

  const handleMin = () => {
    const quantity = newQuantity - 1;
    setNewQuantity(quantity);
    handleChangeQuantity(quantity);
  };

  const handlePlus = () => {
    const quantity = newQuantity + 1;
    setNewQuantity(quantity);
    handleChangeQuantity(quantity);
  };

  const handleChangeQuantity = (qty: number) => {
    clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      props.setLoading(true);

      updateBasket(props.item.id, qty);
      dispatchUpdateItem(props.item.name, qty);
    }, 500);
  };

  return props.item.isLocked && props.item.isFees ? null : (
    <div className="basket-item-container">
      {!props.item.isLocked && (
        <div className="basket-item-side">
          {shopIsIframe || props.editable === false ? (
            <span className={'basket-quantity'}>
              {props.item.quantity}&times;
            </span>
          ) : (
            <DeleteOutlined
              className="delete-item-button"
              onClick={() => handleDeleteItem(props.item)}
            />
          )}
          {props.item.formuleRelationRequired &&
            props.item.formuleRelationRequired.length > 0 && (
              <RequiredProducts
                classIcon="warning-item-button"
                products={props.item.formuleRelationRequired}
              />
            )}
          <div>
            <div className="basket-item-info">
              <div className="basket-name">
                <span>{props.item.name}</span>
                {props.item.sessionTxt && (
                  <ul>
                    {props.item.sessionTxt.split(', ').map((data, i) => (
                      <li key={i}>{data}</li>
                    ))}
                    {props.item.placementInfos &&
                      props.item.placementInfos.map((data) => (
                        <li key={data.unitKey}>
                          {Translator.trans('ticket.placement', {
                            seat: data.unitKey,
                          })}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <div className="basket-item-price">
                <SimplePrice
                  price={props.item.price}
                  currency={shopConfig.locale.currencyCode}
                />
              </div>
            </div>
            {!props.item.placementInfos && !shopIsIframe && (
              <div className="basket-item-info">
                <SimplePrice
                  price={props.item.priceUnit}
                  currency={shopConfig.locale.currencyCode}
                />
                {props.editable !== false && (
                  <Quantity
                    minQuantity={props.item.formuleMinQty}
                    quantityMaxPerSale={props.item.formuleMaxQty}
                    handleMin={handleMin}
                    currentValue={newQuantity}
                    handlePlus={handlePlus}
                    disabled={props.isLoading}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {props.item.isLocked && !props.item.isFees && (
        <div className="basket-item-side">
          <div className="basket-item-info">
            <div className="basket-name">
              <span className={'basket-quantity'}>
                {props.item.quantity}&times;
              </span>
              <span>{props.item.name}</span>
            </div>
            <div className="basket-item-price">
              <SimplePrice
                price={props.item.price}
                currency={shopConfig.locale.currencyCode}
              />
            </div>
          </div>
        </div>
      )}

      {props.item.isFees && (
        <div className="basket-item-info">
          <div className="basket-name">{props.item.name}</div>
          <div>{props.item.quantity}</div>
        </div>
      )}
    </div>
  );
};

export default BasketFormuleItem;
