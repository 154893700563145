import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children: React.ReactElement;
  trigger?: any;
}

const ScrollToTop = (props: ScrollToTopProps) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, props.trigger]);

  return props.children;
};

export default ScrollToTop;
