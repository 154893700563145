import AppLocale from '../lngProvider';

export function getBestSupportedLocale(locale: string): string {
  if (AppLocale.hasOwnProperty(locale)) {
    return locale;
  }
  const lang = locale.split(/_/)[0];
  if (AppLocale.hasOwnProperty(lang)) {
    return lang;
  }
  return 'en';
}
