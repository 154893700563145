import { shopConfig } from '../Globals/ShopConfig';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

function getLocaleName(locale: string, displayLocale: string): string {
  let language = '';
  if (locale) {
    locale = locale.replace('_', '-');
    displayLocale = displayLocale.replace('_', '-');
    try {
      language = new Intl.DisplayNames([locale], {
        type: 'language',
      }).of(displayLocale);
    } catch (error) {
      console.error(error + ' : ' + locale);
    }
  }

  return capitalizeFirstLetter(language);
}

export function getCurrentLocaleName(locale: string): string {
  return getLocaleName(shopConfig.locale.locale, locale);
}

export function getSelfLocaleName(locale: string): string {
  return getLocaleName(locale, locale);
}
