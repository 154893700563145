import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Container from '../../../components/Container/Container';
import HeaderShop from '../../../components/HeaderShop/HeaderShop';
import Translator from '../../../services/translator';
import { Link, useParams } from 'react-router-dom';
import { shopConfig, shopIsIframe } from '../../../Globals/ShopConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetailRequest } from '../../../redux/actions/order.actions';
import { checkReduxResponse } from '../../../services/httpService';
import OrderModel from '../../../Models/OrderModel';
import { formatDateTimeToString } from '../../../services/formatDate';
import StatusComponent from '../../../components/StatusComponent/StatusComponent';
import OrderDetailItem from './OrderDetailItem';
import BarcodeModel from '../../../Models/BarcodeModel';
import OrderItemModel from '../../../Models/OrderItemModel';
import PaymentModel from '../../../Models/PaymentModel';
import { pagesConstant } from '../../../Constants/Constants';
import '../index.css';

const OrderDetail = () => {
  const { id, signature } = useParams<{ id: string; signature: string }>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderModel>(null);
  const bannerPictureUrl = shopConfig.branding.bannerPictureUrl;

  const orderDetail = useSelector((state: any) => state.order.orderDetail);

  const getOrderDetail = useCallback(() => {
    dispatch(
      getOrderDetailRequest({
        id: id,
        signature: signature,
      })
    );
  }, [dispatch, id, signature]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  useEffect(() => {
    if (orderDetail) {
      setIsLoading(orderDetail.loading);
      if (checkReduxResponse(orderDetail, 'order')) {
        setCurrentOrder(orderDetail.order);
      }
    }
  }, [orderDetail]);

  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24}>
          {bannerPictureUrl && !shopIsIframe && (
            <Container className="product-review-banner">
              <img
                className="banner"
                src={bannerPictureUrl}
                alt={'shop banner'}
              />
            </Container>
          )}
          <HeaderShop
            title={Translator.trans('order.number', {
              id: id,
            })}
          >
            <div className="basket-header-link">
              <Link to={pagesConstant.order.list}>
                {Translator.trans('order.button.back_to_orders')}
              </Link>
            </div>
          </HeaderShop>

          <Container className={'order-list-container'}>
            <Skeleton loading={isLoading} active>
              {currentOrder && (
                <>
                  <div className={'order-item-header'}>
                    <StatusComponent type={currentOrder.status} />
                    <span className={'order-list-item-date'}>
                      {formatDateTimeToString(currentOrder.confirmedAt)}
                    </span>
                  </div>

                  {currentOrder.items.map((item: OrderItemModel) =>
                    item.barcodes.length > 0 ? (
                      item.barcodes.map((barcode: BarcodeModel) => (
                        <OrderDetailItem
                          key={barcode.id}
                          item={item}
                          barcode={barcode}
                        />
                      ))
                    ) : (
                      <OrderDetailItem key={item.id} item={item} />
                    )
                  )}

                  <div className={'order-item-info-container'}>
                    <div className={'order-item-payment-method'}>
                      <div>{Translator.trans('payment.methods')}</div>
                      <figure>
                        {currentOrder.payments.map(
                          (payment: PaymentModel) =>
                            payment.paymentMethod.picture && (
                              <img
                                key={payment.id}
                                src={payment.paymentMethod.picture}
                                alt="used payment methods"
                              />
                            )
                        )}
                      </figure>
                    </div>

                    <div className={'order-item-payment-info'}>
                      <div className={'order-item-payment'}>
                        <span>
                          {Translator.trans('accounting.amount.total_exclTax')}
                        </span>
                        <span>{currentOrder.amountExclVat.formattedPrice}</span>
                      </div>

                      <div className={'order-item-payment'}>
                        <span>{Translator.trans('accounting.amount.vat')}</span>
                        <span>{currentOrder.vatAmount.formattedPrice}</span>
                      </div>

                      <div className={'order-item-payment'}>
                        <span>
                          {Translator.trans('accounting.amount.total_inclTax')}
                        </span>
                        <span>{currentOrder.amount.formattedPrice}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Skeleton>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetail;
