import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { sectionsConstants } from '../constants/sections.constants';
import {
  getSectionsSuccess,
  getSectionsFailure,
} from '../actions/sections.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getSectionsFetch() {
  const url = shopConfig.router.productSectionListUrl;
  return await get(getUrl(url));
}

export function* fetchGetSections() {
  try {
    const section = yield call(getSectionsFetch);
    yield put(getSectionsSuccess(section));
  } catch (error) {
    yield put(getSectionsFailure(error));
  }
}

export function* getSections() {
  yield takeLatest(sectionsConstants.GET_SECTIONS_REQUEST, fetchGetSections);
}

export default function* sectionsSaga() {
  yield all([fork(getSections)]);
}
