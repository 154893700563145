import { usersConstants } from '../constants/users.constants';

export const getLoggedUserRequest = (params: object) => ({
  type: usersConstants.GET_LOGGED_USER_REQUEST,
  params,
});

export const getLoggedUserSuccess = (logged: object) => ({
  type: usersConstants.GET_LOGGED_USER_SUCCESS,
  logged,
});

export const getLoggedUserFailure = (error: object) => ({
  type: usersConstants.GET_LOGGED_USER_FAILURE,
  error,
});

export const logUserOut = () => ({
  type: usersConstants.LOG_USER_OUT,
});
