import { basketConstants } from '../constants/basket.constants';
import {
  removeBasketStorage,
  setBasketStorage,
} from '../../services/handleBasketStorage';
import BasketModel from '../../Models/BasketModel';
import { pagesConstant } from '../../Constants/Constants';
import history from '../../services/history';

const initialState = {
  currentBasket: null,
};

let basketCurrent = {};

const basket = (state = initialState, action: any) => {
  switch (action.type) {
    case basketConstants.GET_BASKET_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.GET_BASKET_SUCCESS: {
      basketCurrent = action.basket ? new BasketModel(action.basket) : {};

      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: false },
      };
    }

    case basketConstants.GET_BASKET_FAILURE: {
      let errorMessage = action.error.body?.message;
      let expiredAtDate = action.error.body?.expiredAt;

      if (expiredAtDate) {
        const now = new Date();
        const expiredAt = new Date(expiredAtDate);
        const nextDay = expiredAt.setDate(expiredAt.getDate() + 1);
        const nextDayExpiredDate = new Date(nextDay);
        if (now >= nextDayExpiredDate) {
          removeBasketStorage();
          history.navigate(pagesConstant.tickets.home);
          errorMessage = false;
        }
      }

      return {
        ...state,
        currentBasket: {
          basket: {},
          error: errorMessage,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_SUCCESS: {
      if (action.newItem.signature && action.newItem.id) {
        setBasketStorage(action.newItem.signature, action.newItem.id);
      }

      basketCurrent = new BasketModel(action.newItem.data.basket);

      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error?.body?.message,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_SUCCESS: {
      if (action.newItem.signature && action.newItem.id) {
        setBasketStorage(action.newItem.signature, action.newItem.id);
      }

      basketCurrent = new BasketModel(action.newItem.data.basket);

      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error?.body?.message,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_SUCCESS: {
      basketCurrent = new BasketModel(action.newAccommodation.data);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_SUCCESS: {
      basketCurrent = new BasketModel(action.updateItem.data.basket);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_SUCCESS: {
      basketCurrent = {};
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          code: action.error.body?.code,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          validDiscount: false,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_SUCCESS: {
      basketCurrent = new BasketModel(action.newBasketWithDiscount);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          validDiscount: true,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          validDiscount: false,
          error: action.error.body?.error,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default basket;
