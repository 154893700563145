import AmountModel from './AmountModel';
import {
  ProductStatusConstant,
  ProductTypeConstant,
} from '../Constants/Constants';

export default class ProductModel {
  cashlessValue: AmountModel;
  description: string;
  entryDateStart: null | Date;
  entryDateStop: null | Date;
  fees: AmountModel;
  hasOptions: boolean;
  hasSessions: boolean;
  highlight: boolean;
  id: number;
  isDiscount: boolean;
  isMultipleDay: null | boolean;
  isOneDay: null | boolean;
  name: string;
  orderAvailable: boolean;
  picture: null | string;
  pictures: null | { path: string }[];
  price: AmountModel;
  priceDiscount: null | AmountModel;
  priceLabel: null | PriceLabelModel;
  priceMax: null | AmountModel;
  priceMin: null | AmountModel;
  printAtHome: boolean;
  quantityIncrement: number;
  quantityMaxPerSale: null | number;
  quantityMinPerSale: null | number;
  shortDescription: string;
  status: keyof typeof ProductStatusConstant;
  stockManaged: boolean;
  type: keyof typeof ProductTypeConstant;

  constructor(data: any) {
    this.cashlessValue = new AmountModel(data.cashlessValue);
    this.description = data.description;
    this.entryDateStart = data.entryDateStart
      ? new Date(data.entryDateStart)
      : null;
    this.entryDateStop = data.entryDateStop
      ? new Date(data.entryDateStop)
      : null;
    this.fees = new AmountModel(data.fees);
    this.hasOptions = data.hasOptions;
    this.hasSessions = data.hasSessions;
    this.highlight = data.highlight;
    this.id = data.id;
    this.isDiscount = data.isDiscount;
    this.isMultipleDay = data.isMultipleDay;
    this.isOneDay = data.isOneDay;
    this.name = data.name;
    this.orderAvailable = data.orderAvailable;
    this.picture = data.picture ? data.picture.path : null;
    this.pictures = data.pictures;
    this.price = new AmountModel(data.price);
    this.priceDiscount = data.priceDiscount
      ? new AmountModel(data.priceDiscount)
      : null;
    this.priceLabel = data.priceLabel
      ? new PriceLabelModel(data.priceLabel)
      : null;
    this.priceMax = data.priceMax ? new AmountModel(data.priceMax) : null;
    this.priceMin = data.priceMin ? new AmountModel(data.priceMin) : null;
    this.printAtHome = data.printAtHome;
    this.quantityIncrement = data.quantityIncrement;
    this.quantityMaxPerSale = data.quantityMaxPerSale;
    this.quantityMinPerSale = data.quantityMinPerSale;
    this.shortDescription = data.shortDescription;
    this.status = data.status;
    this.stockManaged = data.stockManaged;
    this.type = data.type;
  }
}

class PriceLabelModel {
  id: number;
  label: string;
  price: AmountModel;

  constructor(data: any) {
    this.id = data.id;
    this.label = data.label;
    this.price = data.price ? new AmountModel(data.price) : null;
  }
}
