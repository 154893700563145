import React from 'react';
import { shopConfig } from '../../Globals/ShopConfig';
import './Wallpaper.css';

const Wallpaper = ({ children }: { children: React.ReactNode }) => (
  <>
    <div
      className="wallpaper-image-container"
      style={{
        backgroundImage:
          'url(' + shopConfig.branding.backgroundPictureUrl + ')',
        backgroundColor: shopConfig.branding.colorBackground,
      }}
    />
    {children}
  </>
);

export default Wallpaper;
