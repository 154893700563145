import { Region } from '@seatsio/seatsio-types';

export default class ShopPlacementMapModel {
  seatsioApiWorkspaceKey: string;
  seatsioApiRegion: Region;

  constructor(data: any) {
    this.seatsioApiWorkspaceKey = data.seatsioApiWorkspaceKey;
    this.seatsioApiRegion = data.seatsioApiRegion.toLowerCase();
  }
}
