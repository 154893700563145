import React from 'react';
import './Container.css';

interface ContainerProps {
  disabled?: boolean;
}
const Container = (
  props: ContainerProps & React.HTMLAttributes<HTMLDivElement>
) => (
  <div
    {...props}
    className={`global-container ${
      props.className !== undefined ? props.className : ''
    }`}
    onClick={props.disabled ? null : props.onClick}
  >
    {props.children}
  </div>
);

export default Container;
