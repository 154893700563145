export const addCustomCssToShop = (css) => {
  if (css) {
    const style = document.createElement('style');

    style.setAttribute('type', 'text/css');
    style.appendChild(document.createTextNode(css));

    document.head.appendChild(style);
  }
};
