import React, { useCallback, useEffect, useState } from 'react';
import { Result, Spin } from 'antd';
import EmisysButton from '../EmisysButton/EmisysButton';
import { shopConfig } from '../../Globals/ShopConfig';
import { useSelector } from 'react-redux';
import Translator from '../../services/translator';
import { FallbackProps } from 'react-error-boundary';
import './ErrorPage.css';

interface ErrorPageProps {
  exception: FallbackProps;
  error?: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const [loading, setLoading] = useState(false);
  const [isErrorSend, setIsErrorSend] = useState(false);
  const loggedUser = useSelector((state: any) => state.users.loggedUser?.user);
  const currentBasket = useSelector(
    (state: any) => state.basket.currentBasket?.basket
  );

  const postErrorSlack = useCallback(() => {
    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        body: JSON.stringify({
          blocks: [
            {
              type: 'header',
              text: {
                type: 'plain_text',
                text: '⚠️ SHOP REACT️',
                emoji: true,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: '*URL :* ' + window.location.href,
              },
            },
            {
              type: 'section',
              fields: [
                {
                  type: 'mrkdwn',
                  text:
                    '*Project :* ' +
                    shopConfig.project.name +
                    '\n <https://audience.byemisys.com/admin/' +
                    shopConfig.project.id +
                    '>',
                },
                {
                  type: 'mrkdwn',
                  text: '*Shop name :*\n' + shopConfig.pos.shopName,
                },
                {
                  type: 'mrkdwn',
                  text:
                    '*Shop user :* ' +
                    (loggedUser?.user
                      ? loggedUser.user.id + '\n' + loggedUser.user.email
                      : '\n No user'),
                },
                {
                  type: 'mrkdwn',
                  text:
                    '*Basket id :* \n' +
                    (currentBasket.id ? currentBasket.id : 'Empty basket'),
                },
              ],
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text:
                  '*User agent :*\n' + navigator.userAgent.substring(0, 256),
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: '```' + props.exception.error.stack + '```',
              },
            },
          ],
        }),
      }
    )
      .then(() => {
        setIsErrorSend(true);
      })
      .catch((error) => {
        console.error('hooks slack error : ', error);
      });
  }, [props.exception, currentBasket, loggedUser?.user]);

  useEffect(() => {
    if (
      props.exception &&
      process.env.NODE_ENV !== 'development' &&
      !isErrorSend
    ) {
      postErrorSlack();
    }
  }, [isErrorSend, postErrorSlack, props.exception]);

  const handleReload = () => {
    setLoading(true);
    window.location.reload();
  };

  return (
    <Spin spinning={loading}>
      <Result
        className="error-informations"
        status="500"
        title={Translator.trans('error.internal_error.title')}
        subTitle={props.error}
        extra={
          <EmisysButton onClick={handleReload}>
            {Translator.trans('error.refreshButton')}
          </EmisysButton>
        }
      />
    </Spin>
  );
};
export default ErrorPage;
