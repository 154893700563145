import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { deleteAllBasketRequest } from '../../redux/actions/basket.actions';
import { Tooltip } from 'antd';
import ModalTimer from '../Modal/ModalTimer';
import Translator from '../../services/translator';
import { checkReduxResponse } from '../../services/httpService';
import Container from '../Container/Container';
import { WarningOutlined } from '@ant-design/icons';
import './Timer.css';

interface TimerInterface {
  showOnly?: boolean;
}

const Timer = (props: TimerInterface) => {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [expireTime, setExpireTime] = useState(null);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const timer: any = useRef(0);

  const currentBasket = useSelector((state: any) => state.basket.currentBasket);

  const deleteAllBasket = useCallback(() => {
    dispatch(deleteAllBasketRequest());
  }, [dispatch]);

  const countDown = useCallback(() => {
    let remaining = countRemaining(expireTime);
    if (remaining && remaining <= 0) {
      remaining = 0;
      clearInterval(timer.current);

      if (!props.showOnly) {
        deleteAllBasket();
        setIsTimeExpired(true);
      }
    }

    setSeconds(computeRemainingSeconds(remaining));
    setMinutes(computeRemainingMinutes(remaining));
  }, [deleteAllBasket, expireTime, props.showOnly]);

  useEffect(() => {
    timer.current = setInterval(countDown, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, [countDown]);

  useEffect(() => {
    if (checkReduxResponse(currentBasket, 'basket')) {
      if (currentBasket.basket?.expireAt && currentBasket.basket?.editable) {
        let newExpireTime = new Date(currentBasket.basket.expireAt);
        let remaining = countRemaining(newExpireTime);
        setExpireTime(newExpireTime);
        setSeconds(computeRemainingSeconds(remaining));
        setMinutes(computeRemainingMinutes(remaining));
      } else {
        setExpireTime(null);
      }
    } else {
      if (expireTime !== null) {
        setExpireTime(null);
      }
    }
    // eslint-disable-next-line
  }, [currentBasket]);

  const formatRemainingTime = (minutes: number, seconds: number) => {
    return ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
  };

  const countRemaining = (expireTime: Date | null) => {
    if (!expireTime) {
      return null;
    }
    return (expireTime.getTime() - new Date().getTime()) / 1000;
  };

  const computeRemainingSeconds = (remainingTime: number | null) => {
    if (!remainingTime) {
      return null;
    }
    return Math.floor(remainingTime % 60);
  };

  const computeRemainingMinutes = (remainingTime: number | null) => {
    if (!remainingTime) {
      return null;
    }
    return Math.floor(remainingTime / 60);
  };

  return (
    <>
      {expireTime && (
        <ShowContainerOrNot showContainer={props.showOnly}>
          <div className="timer-container">
            <div className="timer">
              {Translator.trans('timer.time_left', {
                time: formatRemainingTime(minutes, seconds),
              })}
            </div>
            {!props.showOnly && (
              <Tooltip
                placement="bottom"
                title={Translator.trans('timer.tooltip')}
              >
                <InfoCircleOutlined className="icon-info-circle" />
              </Tooltip>
            )}
          </div>
        </ShowContainerOrNot>
      )}
      <ModalTimer
        isOpen={isTimeExpired}
        handleClose={() => setIsTimeExpired(false)}
      />
    </>
  );
};

const ShowContainerOrNot = (props: {
  showContainer: boolean;
  children?: React.ReactElement;
}) =>
  props.showContainer ? (
    <Container className={'timer-aside-container'}>
      <WarningOutlined />
      <div className={'timer-left-title'}>
        <h3 className={'time-left'}>{Translator.trans('timer.left')}</h3>
        {props.children}
      </div>
    </Container>
  ) : (
    <div className={'timer-aside'}>{props.children} </div>
  );

export default Timer;
