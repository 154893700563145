import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { datesConstants } from '../constants/dates.constants';
import {
  getDatesListSuccess,
  getDatesListFailure,
} from '../actions/dates.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getDatesFetch() {
  const url = shopConfig.router.datesListUrl;
  return await get(getUrl(url));
}

export function* fetchGetDates() {
  try {
    const dates = yield call(getDatesFetch);
    yield put(getDatesListSuccess(dates));
  } catch (error) {
    yield put(getDatesListFailure(error));
  }
}

export function* getDates() {
  yield takeLatest(datesConstants.GET_DATES_LIST_REQUEST, fetchGetDates);
}

export default function* datesSaga() {
  yield all([fork(getDates)]);
}
