import React, { useCallback } from 'react';
import ModalEmisys from './ModalEmisys';
import Translator from '../../services/translator';
import { Collapse } from 'antd';
import { getBaseUrl } from '../../services/parseUrl';
import './Modal.css';

const ModalIntent = (props: { isOpen: boolean }) => {
  const host = window.location.host;
  const origin = window.location.origin;
  const path = getBaseUrl(window.location.pathname);
  const shopUrl = origin + path;

  const getCurrentIntentUrl = useCallback(() => {
    const safariUrl = 'x-safari-' + shopUrl;
    const ChromeUrl =
      'intent://' +
      host +
      path +
      '#Intent;scheme=https;package=com.android.chrome;end';
    const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    return isIOS ? safariUrl : ChromeUrl;
  }, [host, path, shopUrl]);

  return (
    <ModalEmisys
      closable={false}
      open={props.isOpen}
      footer={null}
      width={850}
      centered
      wrapClassName={'intent-modal-wrap'}
    >
      <div className={'intent-modal'}>
        <div>
          <div>
            <h2>{Translator.trans('intent.title')}</h2>
            <p>{Translator.trans('intent.content')}</p>
          </div>

          <Collapse
            ghost
            className={'intent-collapse'}
            expandIconPosition={'end'}
            items={[
              {
                key: '1',
                label: Translator.trans('intent.exit.how'),
                children: (
                  <div>
                    <ul>
                      <li>
                        {Translator.trans('intent.exit.how.copy')}
                        <a
                          onClick={() => {
                            navigator.clipboard.writeText(shopUrl);
                          }}
                        >
                          {shopUrl}
                        </a>
                      </li>
                      <li>{Translator.trans('intent.exit.how.paste')}</li>
                    </ul>

                    <div>
                      <p>{Translator.trans('intent.apologize')}</p>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className={'intent-modal-footer'}>
          <a href={getCurrentIntentUrl()}>
            {Translator.trans('intent.open.browser')}
          </a>
        </div>
      </div>
    </ModalEmisys>
  );
};

export default ModalIntent;
