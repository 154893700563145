export const orderCheckBoxId = {
  accepted: 'accepted',
  acceptCharter: 'acceptCharter',
  requestInvoice: 'requestInvoice',
  newsletterAccepted: 'newsletterAccepted',
};

export default class OrderCheckboxModel {
  id: keyof typeof orderCheckBoxId;
  label: string;
  required: boolean;
  value: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.label = data.label;
    this.required = data.required;
    this.value = data.value;
  }

  static setCheckboxModel(options) {
    return options.map((checkBox) => new OrderCheckboxModel(checkBox));
  }
}
