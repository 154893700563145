import { ticketsConstants } from '../constants/tickets.constants';
import CategoriesModel from '../../Models/CategoriesModel';

const initialState = {
  ticketsList: [],
  ticketDetails: null,
};

const tickets = (state = initialState, action: any) => {
  switch (action.type) {
    case ticketsConstants.GET_TICKETS_REQUEST: {
      return {
        ...state,
        ticketsList: { tickets: [], error: false, loading: true },
      };
    }

    case ticketsConstants.GET_TICKETS_SUCCESS: {
      return {
        ...state,
        ticketsList: {
          tickets: new CategoriesModel(action.tickets),
          error: false,
          loading: false,
        },
      };
    }

    case ticketsConstants.GET_TICKETS_FAILURE: {
      return {
        ...state,
        ticketsList: { tickets: null, error: true, loading: false },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_REQUEST: {
      return {
        ...state,
        ticketDetails: { ticket: null, error: false, loading: true },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        ticketDetails: { ticket: action.ticket, error: false, loading: false },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_FAILURE: {
      return {
        ...state,
        ticketDetails: { ticket: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default tickets;
