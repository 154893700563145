import AmountModel from './AmountModel';
import OrderPaymentItemModel from './OrderPaymentItemModel';

export default class OrderPaymentModel {
  amount: AmountModel;
  confirmedAt: string;
  id: number;
  signature: string;
  totalItems: number;
  vatAmount: number;
  items: OrderPaymentItemModel[];

  constructor(data: any) {
    this.amount = new AmountModel(data.amount);
    this.confirmedAt = data.confirmedAt;
    this.totalItems = data.totalItems;
    this.signature = data.signature;
    this.vatAmount = data.vatAmount.amount;
    this.id = data.id;
    this.items = data.items
      ? data.items.map((item) => new OrderPaymentItemModel(item))
      : [];
  }
}
