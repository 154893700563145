import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { cmsConstants } from '../constants/cms.constants';
import {
  getCmsContentSuccess,
  getCmsContentFailure,
} from '../actions/cms.actions';
import { get, getUrl } from '../../services/httpService';

export async function getCmsContentFetch(arg) {
  const url = arg.cmsUrl;
  return await get(getUrl(url));
}

export function* fetchGetCmsContent(action) {
  try {
    const cmsContent = yield call(getCmsContentFetch, {
      cmsUrl: action.cmsUrl,
    });
    yield put(getCmsContentSuccess(cmsContent));
  } catch (error) {
    yield put(getCmsContentFailure(error));
  }
}

export function* getCmsContent() {
  yield takeLatest(cmsConstants.GET_CMS_CONTENT_REQUEST, fetchGetCmsContent);
}

export default function* cmsSaga() {
  yield all([fork(getCmsContent)]);
}
