import AddressModel from '../AddressModel';

export default class ShopProjectModel {
  id: number;
  name: string;
  audienceStart: Date;
  audienceStop: Date;
  canceled: boolean;
  eventAddress: AddressModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.audienceStart = new Date(data.audienceStart);
    this.audienceStop = new Date(data.audienceStop);
    this.canceled = data.canceled;
    this.eventAddress = data.eventAddress
      ? new AddressModel(data.eventAddress)
      : null;
  }
}
