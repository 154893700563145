import React from 'react';
import emisys_star from '../../assets/images/star.svg';
import ErrorPage from '../ErrorPage/ErrorPage';
import './LoadingShop.css';

interface LoadingShopProps {
  error?: string;
  isLazy?: boolean;
}

const defaultProps = {
  error: '',
  isLazy: false,
};

const LoadingShop = (props: LoadingShopProps) => (
  <div
    className={
      'loading-shop-container' + (props.isLazy ? ' loading-shop-lazy' : '')
    }
  >
    {props.error === '' ? (
      <div className="emisys-star">
        <img src={emisys_star} alt={'loading icon'} />
      </div>
    ) : (
      <ErrorPage exception={null} error={props.error} />
    )}
  </div>
);

LoadingShop.defaultProps = defaultProps;
export default LoadingShop;
