import { searchPanelConstants } from '../constants/searchPanel.constants';

const initialState = {
  arrivalDate: null,
  departureDate: null,
  occupantType: {
    list: [],
    listString: [],
  },
};

const searchPane = (state = initialState, action: any) => {
  switch (action.type) {
    case searchPanelConstants.SET_ACCOMMODATION_ARRIVAL_DATE: {
      return {
        ...state,
        arrivalDate: action.arrivalDate,
      };
    }

    case searchPanelConstants.SET_ACCOMMODATION_DEPARTURE_DATE: {
      return {
        ...state,
        departureDate: action.departureDate,
      };
    }

    case searchPanelConstants.SET_ACCOMMODATION_OCCUPANT_TYPES: {
      return {
        ...state,
        occupantType: {
          list: action.occupantTypes,
          listString: action.occupantString,
        },
      };
    }

    default:
      return state;
  }
};

export default searchPane;
