import { placementConstants } from '../constants/placement.constants';

const initialState = {
  placementList: null,
};

const placement = (state = initialState, action: any) => {
  switch (action.type) {
    case placementConstants.GET_PLACEMENT_LIST_REQUEST: {
      return {
        ...state,
        placementList: { list: [], error: false, loading: true },
      };
    }

    case placementConstants.GET_PLACEMENT_LIST_SUCCESS: {
      return {
        ...state,
        placementList: {
          list: action.response,
          error: false,
          loading: false,
        },
      };
    }

    case placementConstants.GET_PLACEMENT_LIST_FAILURE: {
      return {
        ...state,
        placementList: {
          list: null,
          error: true,
          errorMessage: action.error?.body?.error,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default placement;
