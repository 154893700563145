export const accommodationConstants = {
  GET_OCCUPANT_TYPES_REQUEST: 'GET_OCCUPANT_TYPES_REQUEST',
  GET_OCCUPANT_TYPES_SUCCESS: 'GET_OCCUPANT_TYPES_SUCCESS',
  GET_OCCUPANT_TYPES_FAILURE: 'GET_OCCUPANT_TYPES_FAILURE',

  GET_ACCOMMODATION_LIST_REQUEST: 'GET_ACCOMMODATION_LIST_REQUEST',
  GET_ACCOMMODATION_LIST_SUCCESS: 'GET_ACCOMMODATION_LIST_SUCCESS',
  GET_ACCOMMODATION_LIST_FAILURE: 'GET_ACCOMMODATION_LIST_FAILURE',

  GET_ACCOMMODATION_ASSEMBLIES_REQUEST: 'GET_ACCOMMODATION_ASSEMBLIES_REQUEST',
  GET_ACCOMMODATION_ASSEMBLIES_SUCCESS: 'GET_ACCOMMODATION_ASSEMBLIES_SUCCESS',
  GET_ACCOMMODATION_ASSEMBLIES_FAILURE: 'GET_ACCOMMODATION_ASSEMBLIES_FAILURE',

  GET_ACCOMMODATION_DETAIL_REQUEST: 'GET_ACCOMMODATION_DETAIL_REQUEST',
  GET_ACCOMMODATION_DETAIL_SUCCESS: 'GET_ACCOMMODATION_DETAIL_SUCCESS',
  GET_ACCOMMODATION_DETAIL_FAILURE: 'GET_ACCOMMODATION_DETAIL_FAILURE',
};
