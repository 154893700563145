import UserModel from './UserModel';
import OrderPaymentModel from './OrderPaymentModel';
import ProviderDataModel from './ProviderData/ProviderDataModel';
import { createProviderData } from './ProviderData/ProviderDataCreator';
import AddressModel from './AddressModel';
import PdfFileModel from './PdfFileModel';
import PaymentModel from './PaymentModel';

export default class PaymentResultModel extends PaymentModel {
  customer: UserModel | null;
  environment: string;
  errorMessage: string | null;
  locale: string;
  order: OrderPaymentModel;
  billingAddress: AddressModel | null;
  providerData: ProviderDataModel | null;
  returnUrl: string;
  pdfFiles: PdfFileModel[];

  constructor(data: any) {
    super(data);
    this.customer = data.customer ? new UserModel(data.customer) : null;
    this.environment = data.environment;
    this.errorMessage = data.errorMessage;
    this.locale = data.locale;
    this.order = new OrderPaymentModel(data.order);
    this.provider = data.provider;
    this.providerData = data.providerData
      ? createProviderData(data.provider, data.providerData)
      : null;
    this.returnUrl = data.returnUrl;
    this.pdfFiles = data.pdfFiles?.map((file) => new PdfFileModel(file));
    this.billingAddress = null;
  }
}
