import PaymentMethodModel from './PaymentMethodModel';
import OrderCheckboxModel from './OrderCheckboxModel';
import PaymentBasketModel from './PaymentBasketModel';

export default class PaymentOverviewModel {
  basket: PaymentBasketModel;
  checkboxes: OrderCheckboxModel[];
  paymentMethods: PaymentMethodModel[];

  constructor(data: any) {
    this.basket = new PaymentBasketModel(data.basket);
    this.checkboxes = OrderCheckboxModel.setCheckboxModel(data.checkboxes);
    this.paymentMethods = PaymentMethodModel.setPaymentMethodModel(
      data.paymentMethods
    );
  }
}
