import React, { useCallback, useEffect, useRef } from 'react';
import './IframeOverlap.css';
import audienceLogo from '../../assets/images/audience.png';
import { CloseOutlined } from '@ant-design/icons';
import { getBaseUrl } from '../../services/parseUrl';
import OrderConfirmModel from '../../Models/OrderOverview/OrderConfirmModel';
import Translator from '../../services/translator';

interface IframeOverlapInterface {
  order: OrderConfirmModel;
  closeIframeProvider?: () => void;
  isIframePaymentOpen: boolean;
}

const IframeOverlap = (props: IframeOverlapInterface) => {
  let openedWindow: any = useRef(null);

  const openIframePayment = useCallback(() => {
    const origin = window.location.origin;
    const baseUrl = getBaseUrl(window.location.pathname);
    const newSearch = new URLSearchParams();
    newSearch.set('action', 'payment-checkout');
    newSearch.set('id', props.order.id.toString());
    newSearch.set('signature', props.order.signature);

    openedWindow.current = window.open(
      origin + baseUrl + '?' + newSearch,
      'audience_payment_iframe'
    );

    let timer = setInterval(() => {
      if (openedWindow.current?.closed) {
        props.closeIframeProvider();
        clearInterval(timer);
      }
    }, 500);
  }, [props]);

  useEffect(() => {
    if (props.isIframePaymentOpen) {
      openIframePayment();
    }
  }, [openIframePayment, props.isIframePaymentOpen]);

  const closePopupPayment = () => {
    if (openedWindow.current?.opener) {
      openedWindow.current.close();
    }
    props.closeIframeProvider();
  };

  const focusWindow = () => {
    if (openedWindow.current?.opener) {
      openedWindow.current.focus();
    }
  };

  return (
    props.isIframePaymentOpen && (
      <div className="overlap-iframe">
        <CloseOutlined
          className="overlap-iframe-close"
          onClick={closePopupPayment}
        />
        <div className="overlap-iframe-container">
          <figure className="overlap-iframe-logo">
            <img src={audienceLogo} alt={'logo audience'} />
          </figure>
          <div className="overlap-iframe-info">
            <p>{Translator.trans('iframe.payment.hide')}</p>
            <p>{Translator.trans('iframe.payment.help')}</p>
          </div>
          <div className="overlap-iframe-link" onClick={focusWindow}>
            {Translator.trans('iframe.continue.button')}
          </div>
        </div>
      </div>
    )
  );
};

export default IframeOverlap;
