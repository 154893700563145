import { cmsConstants } from '../constants/cms.constants';

const initialState = {
  currentCmsContent: null,
};

const cms = (state = initialState, action: any) => {
  switch (action.type) {
    case cmsConstants.GET_CMS_CONTENT_REQUEST: {
      return {
        ...state,
        currentCmsContent: { cmsContent: [], error: false, loading: true },
      };
    }

    case cmsConstants.GET_CMS_CONTENT_SUCCESS: {
      return {
        ...state,
        currentCmsContent: {
          cmsContent: action.cmsContent,
          error: false,
          loading: false,
        },
      };
    }

    case cmsConstants.GET_CMS_CONTENT_FAILURE: {
      return {
        ...state,
        currentCmsContent: { cmsContent: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default cms;
