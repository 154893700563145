import ShopConfigModel from '../Models/ShopConfig/ShopConfigModel';

export let shopIsIframe: boolean = false;
export let shopConfig: ShopConfigModel = null;
export const setShopConfig = (config: ShopConfigModel) => {
  shopConfig = config;
};

export const setIframeShop = (isIframe: boolean) => {
  shopIsIframe = isIframe;
};
