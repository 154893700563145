import { paymentProviders } from '../OrderOverview/OrderConfirmModel';
import PayconiqModel from './Payconiq/PayconiqModel';
import VivaWalletModel from './VivaWallet/VivawalletModel';
import PaybeModel from './Paybe/PaybeModel';
import SquareUpModel from './SquareUp/SquareUpModel';
import ProviderNoneModel from './None/ProviderNoneModel';

export const createProviderData = (provider, data) => {
  switch (provider) {
    case paymentProviders.PAYCONIQ:
      return new PayconiqModel(data);
    case paymentProviders.VIVAWALLET:
      return new VivaWalletModel(data);
    case paymentProviders.PAY_BE:
      return new PaybeModel(data);
    case paymentProviders.SQUAREUP:
      return new SquareUpModel(data);
    case paymentProviders.NONE:
      return new ProviderNoneModel(data);
    case paymentProviders.DEBUG:
      return null;
    default:
      return null;
  }
};
