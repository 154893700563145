import { upsellingConstants } from '../constants/upselling.constants';
import UpsellingModel from '../../Models/UpsellingModel';

const initialState = {
  currentUpselling: null,
};

const upselling = (state = initialState, action: any) => {
  switch (action.type) {
    case upsellingConstants.GET_UPSELLING_REQUEST: {
      return {
        ...state,
        currentUpselling: { upselling: null, error: false, loading: true },
      };
    }

    case upsellingConstants.GET_UPSELLING_SUCCESS: {
      return {
        ...state,
        currentUpselling: {
          upselling: action.upselling.map(
            (item: any) => new UpsellingModel(item)
          ),
          error: false,
          loading: false,
        },
      };
    }

    case upsellingConstants.GET_UPSELLING_FAILURE: {
      return {
        ...state,
        currentUpselling: { upselling: null, error: true, loading: false },
      };
    }
    default:
      return state;
  }
};

export default upselling;
