import React from 'react';
import BasketRecap from '../BasketRecap/BasketRecap';
import ModalEmisys from './ModalEmisys';

interface BasketRecapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BasketRecapModal = (props: BasketRecapModalProps) => (
  <ModalEmisys
    open={props.isOpen}
    closable={false}
    onCancel={props.onClose}
    footer={null}
    width={960}
    centered
  >
    <BasketRecap editable={false} onClose={props.onClose} />
  </ModalEmisys>
);
export default BasketRecapModal;
