import ShopBrandingModel from '../Models/ShopConfig/ShopBrandingModel';

function initColors(branding: ShopBrandingModel) {
  const element = document.documentElement;

  element.style.setProperty('--primary-color', branding.colorPrimary);
  element.style.setProperty('--secondary-color', branding.colorSecondary);
  element.style.setProperty('--background-color', branding.colorBackground);
}

export default initColors;
