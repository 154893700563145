import React from 'react';
import { CookieConsent } from 'react-cookie-consent';
import Translator from '../../services/translator';
import { grantConsent, revokeConsent } from '../../services/tracking';
import './CookieContainer.css';

interface CookieContainerProps {
  showCookieContent: boolean;
  hideContent: () => void;
}

const CookieContainer = (props: CookieContainerProps) => {
  const acceptCookies = () => {
    grantConsent();
    props.hideContent();
  };

  const revokeCookies = () => {
    revokeConsent();
    props.hideContent();
  };

  return (
    <CookieConsent
      visible={props.showCookieContent ? 'show' : 'byCookieValue'}
      containerClasses={'cookies-container'}
      enableDeclineButton
      flipButtons
      buttonWrapperClasses={'cookie-buttons-container'}
      buttonText={Translator.trans('cookies.accept')}
      buttonClasses={'cookie-accept-button'}
      declineButtonText={Translator.trans('cookies.decline')}
      declineButtonClasses={'cookie-decline-button'}
      onAccept={acceptCookies}
      onDecline={revokeCookies}
    >
      {Translator.trans('cookies.text')}
    </CookieConsent>
  );
};

export default CookieContainer;
