import React from 'react';
import audienceLogo from '../../assets/images/audience.png';
import EmisysButton from '../EmisysButton/EmisysButton';
import Translator from '../../services/translator';
import './PreviewBanner.css';

const PreviewBanner = () => (
  <div className={'preview-banner-container'}>
    <img src={audienceLogo} alt={'logo of audience'} />

    <div className={'info-preview-banner'}>
      {Translator.trans('shop.preview.banner.text')}
    </div>

    <EmisysButton
      className={'preview-banner-button'}
      onClick={() => alert('go to the admin page')} // TODO: get link to admin page
    >
      {Translator.trans('shop.preview.banner.button')}
    </EmisysButton>
  </div>
);

export default PreviewBanner;
