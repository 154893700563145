import React from 'react';
import Container from '../Container/Container';
import './HeaderShop.css';

interface HeaderShopProps {
  title: string;
  children?: React.ReactNode;
}

const HeaderShop = (props: HeaderShopProps) => (
  <Container className="header-container">
    <div className="basket-header">
      <div className="basket-header-title">{props.title}</div>
      {props.children}
    </div>
  </Container>
);

export default HeaderShop;
