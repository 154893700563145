export default class BarcodeModel {
  id: number;
  attendeeDisplayName: string;
  attendeePlacementUnitLabel: string | null;
  isDownloadable: boolean;
  isExchangeable: boolean;
  signature: string;
  downloadHash: string;
  downloadLink: string;

  constructor(data: any) {
    this.id = data.id;
    this.attendeeDisplayName = data.attendeeDisplayName;
    this.attendeePlacementUnitLabel = data.attendeePlacementUnitLabel;
    this.isDownloadable = data.isDownloadable;
    this.isExchangeable = data.isExchangeable;
    this.signature = data.signature;
    this.downloadHash = data.downloadHash;
    this.downloadLink = data.downloadLink;
  }
}
