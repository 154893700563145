import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { usersConstants } from '../constants/users.constants';
import {
  getLoggedUserSuccess,
  getLoggedUserFailure,
} from '../actions/users.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getLoggedUserFetch(params) {
  const url = shopConfig.router.userProfileUrl;
  return await get(getUrl(url, params.args));
}

export function* fetchGetLoggedUser(action) {
  try {
    const user = yield call(getLoggedUserFetch, {
      args: action.params,
    });
    yield put(getLoggedUserSuccess(user));
  } catch (error) {
    yield put(getLoggedUserFailure(error));
  }
}

export function* getLoggedUser() {
  yield takeLatest(usersConstants.GET_LOGGED_USER_REQUEST, fetchGetLoggedUser);
}

export default function* usersSaga() {
  yield all([fork(getLoggedUser)]);
}
