import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { sessionsConstants } from '../constants/sessions.constants';
import {
  getSessionsSuccess,
  getSessionsFailure,
} from '../actions/sessions.actions';
import { get, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getSessionsFetch(arg) {
  const url = shopConfig.router.productSessionListUrl;
  return await get(getUrl(url, { product_id: arg.options }));
}

export function* fetchGetSessions(action) {
  try {
    const sessions = yield call(getSessionsFetch, {
      options: action.options,
    });
    yield put(getSessionsSuccess(sessions));
  } catch (error) {
    yield put(getSessionsFailure(error));
  }
}

export function* getSessions() {
  yield takeLatest(sessionsConstants.GET_SESSIONS_REQUEST, fetchGetSessions);
}

export default function* sessionsSaga() {
  yield all([fork(getSessions)]);
}
