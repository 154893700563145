import { formatMoney } from '../services/formatMoney';
import { shopConfig } from '../Globals/ShopConfig';

export default class AmountModel {
  amount: number;
  currency: string;
  formattedPrice: string;

  constructor(data: any) {
    this.amount = data.amount;
    this.currency = data.currency;
    this.formattedPrice = formatMoney(
      data.currency,
      shopConfig.locale.locale,
      data.amount
    );
  }
}
