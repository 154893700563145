import { datesConstants } from '../constants/dates.constants';

export const getDatesListRequest = () => ({
  type: datesConstants.GET_DATES_LIST_REQUEST,
});

export const getDatesListSuccess = (dates: object) => ({
  type: datesConstants.GET_DATES_LIST_SUCCESS,
  dates,
});

export const getDatesListFailure = (error: object) => ({
  type: datesConstants.GET_DATES_LIST_FAILURE,
  error,
});
