import ReactGA from 'react-ga4';
import ReactPixel from '@bettercart/react-facebook-pixel';
import AmountModel from '../Models/AmountModel';
import BasketModel from '../Models/BasketModel';
import { shopConfig } from '../Globals/ShopConfig';
import { getCookieConsentValue } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';

/* Events:
 * https://www.facebook.com/business/help/402791146561655?id=1205376682832142
 * https://support.google.com/analytics/answer/9267735?hl=fr
 */

export function initializeGoogleAnalytics(googleId: string): void {
  const emisysGA = process.env.REACT_APP_EMISYS_GA;

  const trackers = [
    {
      trackingId: emisysGA,
      gaOptions: {
        name: 'emisysTracker',
        send_page_view: false,
      },
    },
  ];

  if (googleId) {
    trackers.push({
      trackingId: googleId,
      gaOptions: {
        name: 'shopTracker',
        send_page_view: false,
      },
    });
  }

  if (!getCookieConsentValue()) {
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  }

  ReactGA.initialize(trackers);
}

export function initializeGoogleTagManager(tag: string): void {
  if (tag) {
    TagManager.initialize({
      gtmId: tag,
      dataLayer: {
        userProject: shopConfig.project.name,
      },
    });
  }
}

export function initializeFacebookPixel(facebookId: string): void {
  const options = {
    autoConfig: true,
    debug: true,
  };
  if (facebookId) {
    ReactPixel.init(facebookId, null, options);
  }
}

export function grantConsent() {
  ReactGA.gtag('consent', 'update', {
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
  ReactPixel.grantConsent();
}

export function revokeConsent() {
  ReactGA.gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  });
  ReactPixel.revokeConsent();
}

export function dispatchViewPage(url: string): void {
  console.info('[Emisys] - Dispatch: View page');
  // When the user comes on a new page.
  ReactGA.send({ hitType: 'pageview', page: url });
  ReactPixel.pageView();
}

export function dispatchLogin(userId: string | number): void {
  console.info('[Emisys] - Dispatch: Login');
  // When a user is logged in.
  ReactGA.event('login', {
    value: userId,
  });

  ReactPixel.track('login', {
    value: userId,
  });
}

export function dispatchRegister(userId: string | number): void {
  console.info('[Emisys] - Dispatch: Sign up');
  // When a registration form is completed.
  ReactGA.event('sign_up', {
    label: userId,
  });

  ReactPixel.track('CompleteRegistration', {
    label: userId,
  });
}

export function dispatchOpenDrawer(productName: string): void {
  console.info('[Emisys] - Dispatch: Open drawer');
  // When the user open the Drawer.
  ReactGA.event('view_item', {
    label: productName,
  });

  ReactPixel.track('View item', {
    label: productName,
  });
}

export function dispatchAddToCart(
  productName: string,
  productPrice: number
): void {
  console.info('[Emisys] - Dispatch: Add to card');
  // When a product is added to the shopping cart. A person clicks on an add to cart button.
  ReactGA.event('add_to_cart', {
    label: productName,
    value: productPrice,
  });

  ReactPixel.track('AddToCart', {
    label: productName,
    value: productPrice,
  });
}

export function dispatchUpdateItem(
  productName: string,
  productQty: number
): void {
  console.info('[Emisys] - Dispatch: Update item');
  // When the quantity of a product is updated in the shopping cart.
  ReactGA.event({
    category: 'action',
    action: 'UpdateItem',
    label: productName,
    value: productQty,
  });

  ReactPixel.track('UpdateItem', {
    label: productName,
    quantity: productQty,
  });
}

export function dispatchRemoveToCart(
  productName: string,
  productPrice: number
): void {
  console.info('[Emisys] - Dispatch: Remove from cart');
  // When a product is removed from the shopping cart.
  ReactGA.event('remove_from_cart', {
    label: productName,
    value: productPrice,
  });

  ReactPixel.track('RemoveFromCart', {
    label: productName,
    value: productPrice,
  });
}

export function dispatchClearBasket(): void {
  console.info('[Emisys] - Dispatch: Clear basket');
  // When the shopping cart is cleared.
  ReactGA.event({
    category: 'action',
    action: 'ClearBasket',
  });

  ReactPixel.track('ClearBasket');
}

export function dispatchInitiatePayment(payment: {
  currency: string;
  value: string;
}): void {
  console.info('[Emisys] - Dispatch: Begin checkout');
  // Start of payment process.
  ReactGA.event('begin_checkout', {
    currency: payment.currency,
    value: payment.value,
  });

  ReactPixel.track('InitiateCheckout');
}

export function dispatchPurchase(
  payment: {
    amount: AmountModel;
  } | null,
  basket: BasketModel
): void {
  console.info('[Emisys] - Dispatch: Purchase');

  const currentAmount = payment ? payment.amount.amount : 0;
  const currentCurrency = payment
    ? payment.amount.currency
    : shopConfig.locale.currencyCode;

  // When a purchase is made or checkout flow is completed.
  let googleParams = {
    transaction_id: basket.id,
    value: currentAmount,
    currency: currentCurrency,
    tax: basket.vatAmount,
    shipping: 0,
    items: basket.items.map((item) => ({
      item_id: item.id,
      item_name: item.name,
      quantity: item.quantity,
      price: item.price,
    })),
  };

  let facebookParams = {
    value: currentAmount,
    currency: currentCurrency,
  };

  ReactGA.event('purchase', googleParams);

  ReactPixel.track('Purchase', facebookParams, { eventID: basket.id });
}

export function dispatchShare(method: string): void {
  console.info('[Emisys] - Dispatch: Share');
  const params = {
    method: method,
  };

  ReactGA.event('share', params);

  ReactPixel.track('Share', params);
}
