import { formatMoney } from '../../services/formatMoney';
import Translator from '../../services/translator';
import { shopConfig } from '../../Globals/ShopConfig';
import { currencyPrecision } from '../../Constants/Constants';
import './Price.css';

interface SimplePriceProps {
  price: number;
  currency: string;
  isNotFree?: boolean;
}
const SimplePrice = (props: SimplePriceProps) => {
  const { price, currency, isNotFree } = props;
  const locale = shopConfig.locale.locale;

  let value;
  if (isNotFree) {
    value = formatMoney(currency, locale, price);
  } else {
    value =
      Math.abs(price) >= currencyPrecision
        ? formatMoney(currency, locale, price)
        : Translator.trans('accounting.amount.free');
  }
  return value;
};

export default SimplePrice;
