type translationType = {
  locale: string;
  name: string;
  longDescription: string;
};
export const getLocalTranslation = (
  translations: translationType[],
  locale: string
): translationType => {
  const currentTranslation = translations.find(
    (translation): boolean => translation.locale === locale
  );
  return currentTranslation || translations[0];
};
