import React from 'react';
import { formatMoney } from '../../services/formatMoney';
import Translator from '../../services/translator';
import { shopConfig } from '../../Globals/ShopConfig';
import './Price.css';

interface PriceProps {
  currency: string;
  currentPrice: number;
  priceDiscount?: number | null;
  priceMin?: number | null;
}
const Price = (props: PriceProps) => {
  const {
    currency,
    currentPrice,
    priceDiscount = null,
    priceMin = null,
  } = props;

  const locale = shopConfig.locale.locale;

  return (
    <>
      {priceDiscount !== null ? (
        <>
          <span className="first-price">
            {formatMoney(currency, locale, currentPrice)}
          </span>
          <span className="current-price">
            {formatMoney(currency, locale, priceDiscount)}
          </span>
        </>
      ) : (
        <span className="current-price">
          {priceMin !== 0 && priceMin !== null ? (
            formatMoney(currency, locale, priceMin)
          ) : currentPrice !== 0 ? (
            formatMoney(currency, locale, currentPrice)
          ) : (
            <span className="current-free-price">
              {Translator.trans('accounting.amount.free')}
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default Price;
