import { placementConstants } from '../constants/placement.constants';

export const getPlacementListRequest = (params: any) => ({
  type: placementConstants.GET_PLACEMENT_LIST_REQUEST,
  params,
});

export const getPlacementListSuccess = (response: any) => ({
  type: placementConstants.GET_PLACEMENT_LIST_SUCCESS,
  response,
});

export const getPlacementListFailure = (error: object) => ({
  type: placementConstants.GET_PLACEMENT_LIST_FAILURE,
  error,
});
