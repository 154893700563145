import React from 'react';
import BarcodeModel from '../../../Models/BarcodeModel';
import OrderItemModel from '../../../Models/OrderItemModel';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import { FilePdfOutlined } from '@ant-design/icons';
import Translator from '../../../services/translator';
import SimplePrice from '../../../components/Price/SimplePrice';
import { currencyPrecision } from '../../../Constants/Constants';
import { formatDateTimeToString } from '../../../services/formatDate';
import { shopConfig } from '../../../Globals/ShopConfig';
import { getUrl } from '../../../services/httpService';
import '../index.css';

interface OrderDetailItemInterface {
  item: OrderItemModel;
  barcode?: BarcodeModel;
}

const OrderDetailItem = (props: OrderDetailItemInterface) => {
  function createDownloadUrl() {
    let url = '';
    if (props.barcode.downloadHash && props.barcode.downloadLink) {
      url = getUrl(shopConfig.router.barcodeDownloadPdfUrl, {
        downloadHash: props.barcode.downloadHash,
        downloadLink: props.barcode.downloadLink,
      }).toString();
    }
    return url;
  }

  return (
    <div className={'order-detail-item-container'}>
      <div className={'order-detail-item-left'}>
        <h2>{props.item.name}</h2>
        <ul className={'order-detail-item-info'}>
          {props.barcode && <li>{props.barcode.attendeeDisplayName}</li>}
          {props.item.session && (
            <>
              <li>{formatDateTimeToString(props.item.session.dateStart)}</li>
              {props.item.session.language && (
                <li>{props.item.session.language}</li>
              )}

              {props.item.session.places.length > 0 && (
                <li>{props.item.session.places.join(', ')}</li>
              )}

              {props.item.session.performers.length > 0 && (
                <li>{props.item.session.performers.join(', ')}</li>
              )}
            </>
          )}
          {props.barcode?.attendeePlacementUnitLabel && (
            <li>
              {Translator.trans('ticket.placement', {
                seat: props.barcode.attendeePlacementUnitLabel,
              })}
            </li>
          )}
        </ul>
      </div>

      <div className={'order-detail-item-right'}>
        <div className={'order-detail-item-price'}>
          <span className={'order-detail-item-unit-price'}>
            <SimplePrice
              price={props.item.unitPriceInclTax.amount}
              currency={props.item.unitPriceInclTax.currency}
            />

            {props.item.quantity > 1 &&
              props.item.quantity !== props.item.barcodes.length && (
                <div className={'order-detail-item-quantity'}>
                  &times;{props.item.quantity}
                </div>
              )}
          </span>

          {props.item.quantity > 1 &&
            props.item.quantity !== props.item.barcodes.length &&
            Math.abs(props.item.priceInclTax.amount) >= currencyPrecision && (
              <span className={'order-detail-item-full-price'}>
                {props.item.priceInclTax.formattedPrice}
              </span>
            )}
        </div>
        {props.barcode?.isDownloadable && (
          <EmisysButton
            href={createDownloadUrl()}
            className={'order-detail-item-download'}
            icon={<FilePdfOutlined />}
          >
            {Translator.trans('order.download')}
          </EmisysButton>
        )}
      </div>
    </div>
  );
};

export default OrderDetailItem;
