import ItemModel from './ItemModel';
import taxDetailModel from './taxDetailModel';
import PdfFileModel from './PdfFileModel';
import { BasketStatusConstant } from '../Constants/Constants';

export default class BasketModel {
  confirmedAt: string;
  editable: boolean;
  feeAmount: number;
  hasAttendeesForms: boolean;
  expireAt: string | null;
  id: number;
  isPriceIncludeVat: boolean;
  items: ItemModel[];
  signature: string;
  status: keyof typeof BasketStatusConstant;
  subTotal: number;
  table: number | null;
  taxDetails: taxDetailModel[];
  totalItems: number;
  totalPrice: number;
  totalPriceWithVat: number;
  totalPriceWithoutVat: number;
  vatAmount: number;
  pdfFiles: PdfFileModel[] | null;

  constructor(data: any) {
    this.confirmedAt = data.confirmedAt;
    this.editable = data.editable;
    this.feeAmount = data.feeAmount;
    this.hasAttendeesForms = data.hasAttendeesForms;
    this.expireAt = data.expireAt;
    this.id = data.id;
    this.isPriceIncludeVat = data.isPriceIncludeVat;
    this.items = data.items
      ? data.items.map((item) => new ItemModel(item))
      : [];
    this.signature = data.signature;
    this.status = data.status;
    this.subTotal = data.subTotal;
    this.table = data.table;
    this.taxDetails = taxDetailModel.separateObject(data.taxDetails);
    this.totalItems = data.totalItems;
    this.totalPrice = data.totalPrice;
    this.totalPriceWithVat = data.totalPriceWithVat;
    this.totalPriceWithoutVat = data.totalPriceWithoutVat;
    this.vatAmount = data.vatAmount;
    this.pdfFiles = data.pdfFiles?.map((file) => new PdfFileModel(file));
  }
}
