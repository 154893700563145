export default class ShopPosModel {
  id: number;
  slug: string;
  name: string;
  closed: boolean;
  hasDiscount: boolean;
  requirePassword: boolean;
  shopName: string;
  requireAuth: boolean;
  cookiePath: string;
  showAvailabilityIndicators: boolean;
  showProductPicture: boolean;
  collapseCategories: boolean;
  showDateSelection: boolean;
  forceDateSelection: boolean;
  newBasketToken: string | null;
  preview: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.slug = data.slug;
    this.name = data.name;
    this.closed = data.closed;
    this.hasDiscount = data.hasDiscount;
    this.requirePassword = data.requirePassword;
    this.shopName = data.shopName;
    this.requireAuth = data.requireAuth;
    this.cookiePath = data.cookiePath;
    this.showAvailabilityIndicators = data.showAvailabilityIndicators;
    this.showProductPicture = data.showProductPicture;
    this.collapseCategories = data.collapseCategories;
    this.showDateSelection = data.showDateSelection;
    this.forceDateSelection = data.forceDateSelection;
    this.newBasketToken = data.newBasketToken;
    this.preview = data.preview;
  }

  setNewBasketToken(token) {
    this.newBasketToken = token;
  }
}
