import React, { useEffect } from 'react';
import { Modal, ModalProps } from 'antd';
import { shopIsIframe } from '../../Globals/ShopConfig';
import './Modal.css';

const ModalEmisys = (props: ModalProps) => {
  useEffect(() => {
    if (props.open && shopIsIframe) {
      parent.postMessage({ type: 'emisys_audience_overlap', value: true }, '*');
    }

    return () => {
      if (shopIsIframe) {
        parent.postMessage(
          { type: 'emisys_audience_overlap', value: false },
          '*'
        );
      }
    };
  }, [props.open]);

  return <Modal {...props}>{props.children}</Modal>;
};

export default ModalEmisys;
