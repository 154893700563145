import CategoryModel from './CategoryModel';

export default class CategoriesModel {
  categories: CategoryModel[] | undefined;

  constructor(data: any) {
    this.categories = data.categories?.map(
      (category: any) => new CategoryModel(category)
    );
  }
}
