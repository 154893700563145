import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpsellingRequest } from '../../redux/actions/upselling.actions';
import { Skeleton } from 'antd';
import Title from '../Title/Title';
import Container from '../Container/Container';
import UpsellingItem from './UpsellingItem';
import Translator from '../../services/translator';
import { checkReduxResponse } from '../../services/httpService';
import './Upselling.css';
import UpsellingModel from '../../Models/UpsellingModel';

const Upselling = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [upsellingItems, setUpsellingItems] = useState<UpsellingModel[]>([]);

  const currentUpselling = useSelector(
    (state: any) => state.upselling.currentUpselling
  );

  useEffect(() => {
    dispatch(getUpsellingRequest());
  }, [dispatch]);

  useEffect(() => {
    if (currentUpselling) {
      setLoading(currentUpselling.loading);
      if (checkReduxResponse(currentUpselling, 'upselling')) {
        setUpsellingItems(currentUpselling.upselling);
      }
    }
  }, [currentUpselling]);

  return (
    upsellingItems.length !== 0 && (
      <Container className={'upselling-container'}>
        <Title value={Translator.trans('upselling.title')} />
        <Skeleton active loading={loading}>
          <div>
            <div className="upselling-items-container">
              {upsellingItems.map((data: UpsellingModel) => (
                <UpsellingItem key={data.id} upsellingItem={data} />
              ))}
            </div>
          </div>
        </Skeleton>
      </Container>
    )
  );
};

export default Upselling;
