import { upsellingConstants } from '../constants/upselling.constants';

export const getUpsellingRequest = () => ({
  type: upsellingConstants.GET_UPSELLING_REQUEST,
});

export const getUpsellingSuccess = (upselling: object) => ({
  type: upsellingConstants.GET_UPSELLING_SUCCESS,
  upselling,
});

export const getUpsellingFailure = (error: object) => ({
  type: upsellingConstants.GET_UPSELLING_FAILURE,
  error,
});
