import Translator from '../services/translator';

export const currencyPrecision = 0.01;

export const DATE = {
  day: 'day',
  month: 'month',
  year: 'year',
};

export const CONSTANTSCMS = {
  TERMSURL: 'termsUrl',
  LEGALURL: 'legalUrl',
  POLICYURL: 'policyUrl',
  INFOSURL: 'infoUrl',
};

export const basketTabs = {
  VALIDATION: 'validation',
  CUSTOMER: 'customer-info',
  ATTENDEE: 'attendee-info',
  PAYMENT: 'payment',
};

export const shopConstants = {
  SIGNIN: 'signIn',
  SIGNUP: 'signUp',
  FORGOTPASSWORD: 'forgotten-password',
  RESETPASSWORD: 'reset-password',
};

export const sessionFilters = {
  languages: {
    key: 'languages',
    label: Translator.trans('session.filters.languages'),
  },
  performers: {
    key: 'performers',
    label: Translator.trans('session.filters.performer'),
  },
  places: {
    key: 'places',
    label: Translator.trans('session.filters.places'),
  },
};

export const formTypeConstant = {
  TextType: 'TextType',
  UrlType: 'UrlType',
  BirthdayType: 'BirthdayType',
  PhoneNumberType: 'PhoneNumberType',
  GenderType: 'GenderType',
  CountryType: 'CountryType',
  CheckboxType: 'CheckboxType',
  ChoiceType: 'ChoiceType',
  EmailType: 'EmailType',
  DynFormFileType: 'DynFormFileType',
  IntegerType: 'IntegerType',
  TextareaType: 'TextareaType',
  UserPictureType: 'UserPictureType',
  UserNationalityCollectionType: 'UserNationalityCollectionType',
  LanguageType: 'LanguageType',
  UserLanguageCollectionType: 'UserLanguageCollectionType',
};

export const BasketStatusConstant = {
  payment_accept: 'payment_accept',
  cancel: 'cancel',
  expired: 'expired',
  draft: 'draft',
  awaiting_payment_online: 'awaiting_payment_online',
  awaiting_payment_bank: 'awaiting_payment_bank',
  payment_check: 'payment_check',
  payment_via_invoice: 'payment_via_invoice',
  error: 'error',
  unspecified: 'unspecified',
};

export const PaymentStatusConstant = {
  paid: 'paid',
  cancel: 'cancel',
  complete: 'complete',
  verification: 'verification',
  notStarted: 'notStarted',
  waitPayment: 'waitPayment',
  technicalProblem: 'technicalProblem',
  canceled: 'canceled',
  error: 'error',
  unspecified: 'unspecified',
};

export const ProductStatusConstant = {
  soldout: 'soldout',
  comingsoon: 'comingsoon',
  expired: 'expired',
  onsale: 'onsale',
};

export const ProductTypeConstant = {
  ticket: 'ticket',
  pack: 'pack',
  topup: 'topup',
  accommodation: 'accommodation',
  transportation: 'transportation',
  drink: 'drink',
  food: 'food',
  extra: 'extra',
  shop: 'shop',
  discount: 'discount',
};

export const pagesConstant = {
  index: '/',
  auth: '/auth/',
  accommodation: {
    home: '/accommodation',
    detail: '/detail/accommodation/',
  },
  basket: '/basket',
  checkout: '/checkout',
  order: {
    list: '/orders',
    detail: '/detail/order/',
    cancel: '/order/cancel',
    success: '/order/success',
    response: '/order/',
  },
  page: '/page/',
  payment: {
    response: '/payment/',
    authorized: '/payment/authorized',
    success: '/payment/success',
    failure: '/payment/failure',
  },
  shop: {
    canceled: '/event/canceled',
    closed: '/shop/closed',
    private: '/shop/private',
  },
  tickets: {
    home: '/tickets',
    detail: '/detail/ticket/',
  },
  loading: {
    home: '/loader',
  },
};
