import AmountModel from './AmountModel';
import BarcodeModel from './BarcodeModel';
import SessionModel from './SessionModel';

export default class OrderItemModel {
  id: number;
  barcodes: BarcodeModel[];
  isDiscount: boolean;
  isFee: boolean;
  name: string;
  unitPriceInclTax: AmountModel;
  priceInclTax: AmountModel;
  quantity: number;
  session: SessionModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.barcodes = data.barcodes.map((barcode) => new BarcodeModel(barcode));
    this.isDiscount = data.isDiscount;
    this.isFee = data.isFee;
    this.name = data.name;
    this.unitPriceInclTax = new AmountModel(data.unitPriceInclTax);
    this.priceInclTax = new AmountModel(data.priceInclTax);
    this.quantity = data.quantity;
    this.session = data.session ? new SessionModel(data.session) : null;
  }
}
