import { paymentMethodConstants } from '../constants/paymentMethod.constants';

export const getPaymentMethodRequest = () => ({
  type: paymentMethodConstants.GET_PAYMENTMETHOD_REQUEST,
});

export const getPaymentMethodSuccess = (paymentMethod: object) => ({
  type: paymentMethodConstants.GET_PAYMENTMETHOD_SUCCESS,
  paymentMethod,
});

export const getPaymentMethodFailure = (error: object) => ({
  type: paymentMethodConstants.GET_PAYMENTMETHOD_FAILURE,
  error,
});
