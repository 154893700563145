import { loginConstants } from '../constants/login.constants';
import { addTokenToStorage } from '../../services/handleTokens';

const initialState = {
  newAccount: null,
  account: null,
};

const login = (state = initialState, action: any) => {
  switch (action.type) {
    case loginConstants.POST_CREATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        newAccount: { account: null, error: false, loading: true },
      };
    }

    case loginConstants.POST_CREATE_ACCOUNT_SUCCESS: {
      addTokenToStorage(action.dataToken);

      return {
        ...state,
        newAccount: {
          account: action.dataToken,
          error: false,
          loading: false,
        },
      };
    }

    case loginConstants.POST_CREATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        newAccount: {
          account: action.error,
          error: true,
          loading: false,
        },
      };
    }

    case loginConstants.POST_CONNECT_ACCOUNT_REQUEST:
    case loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_REQUEST:
    case loginConstants.POST_CONNECT_APPLE_ACCOUNT_REQUEST:
    case loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_REQUEST: {
      return {
        ...state,
        account: { connectedAccount: null, error: false, loading: true },
      };
    }

    case loginConstants.POST_CONNECT_ACCOUNT_SUCCESS:
    case loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_SUCCESS:
    case loginConstants.POST_CONNECT_APPLE_ACCOUNT_SUCCESS:
    case loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_SUCCESS: {
      addTokenToStorage(action.dataToken);

      return {
        ...state,
        account: {
          connectedAccount: true,
          error: false,
          loading: false,
        },
      };
    }

    case loginConstants.POST_CONNECT_ACCOUNT_FAILURE:
    case loginConstants.POST_CONNECT_GOOGLE_ACCOUNT_FAILURE:
    case loginConstants.POST_CONNECT_APPLE_ACCOUNT_FAILURE:
    case loginConstants.POST_CONNECT_FACEBOOK_ACCOUNT_FAILURE: {
      return {
        ...state,
        account: {
          connectedAccount: action.error,
          error: true,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default login;
