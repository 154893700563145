import { customerConstants } from '../constants/customer.constants';
import CustomerFormModel from '../../Models/Form/CustomerFormModel';

const initialState = {
  customerForm: null,
  newCustomerFrom: null,
};

const customer = (state = initialState, action: any) => {
  switch (action.type) {
    case customerConstants.GET_CUSTOMER_FORM_REQUEST: {
      return {
        ...state,
        customerForm: { form: [], error: false, loading: true },
      };
    }

    case customerConstants.GET_CUSTOMER_FORM_SUCCESS: {
      return {
        ...state,
        customerForm: {
          form: new CustomerFormModel(action.form),
          error: false,
          loading: false,
        },
      };
    }

    case customerConstants.GET_CUSTOMER_FORM_FAILURE: {
      return {
        ...state,
        customerForm: {
          form: null,
          error: action.error,
          loading: false,
        },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_REQUEST: {
      return {
        ...state,
        newCustomerFrom: { form: [], error: false, loading: true },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_SUCCESS: {
      return {
        ...state,
        newCustomerFrom: {
          form: action.response,
          error: false,
          loading: false,
        },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_FAILURE: {
      return {
        ...state,
        newCustomerFrom: {
          form: null,
          error: action.error.body,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default customer;
