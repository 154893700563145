import Translator from './translator';
/*
validateFormMessage for Ant Design.
--> https://ant.design/components/form/

default verification error messages.
--> https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
*/

export let validateFormMessages = {};

export function updateValidateFromMessage() {
  const typeTemplate = Translator.trans('error.form.type', {
    label: '${label}',
    type: '${type}',
  });

  validateFormMessages = {
    default: Translator.trans('error.form.default', { label: '${label}' }),
    required: Translator.trans('error.form.required', {
      label: '${label}',
    }),
    date: {
      format: Translator.trans('error.form.date.format', {
        label: '${label}',
      }),
      invalid: Translator.trans('error.form.date.invalid', {
        label: '${label}',
      }),
    },
    types: {
      string: typeTemplate,
      method: typeTemplate,
      array: typeTemplate,
      object: typeTemplate,
      number: typeTemplate,
      date: typeTemplate,
      boolean: typeTemplate,
      integer: typeTemplate,
      float: typeTemplate,
      regexp: typeTemplate,
      email: typeTemplate,
      url: typeTemplate,
      hex: typeTemplate,
    },
  };
}
