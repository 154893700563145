import { getBasketStorage } from './handleBasketStorage';

const getBasketSession = (): {
  id: string;
  signature: string;
} | null => {
  let currentId = '';
  let currentSignature = '';
  const basketSession = getBasketStorage();
  const getUrl = new URLSearchParams(window.location.search);

  if (getUrl.has('id') && getUrl.has('signature')) {
    currentId = getUrl.get('id');
    currentSignature = getUrl.get('signature');
  } else if (basketSession) {
    currentId = basketSession.id.toString();
    currentSignature = basketSession.signature;
  }

  if (currentId && currentSignature) {
    return {
      id: currentId,
      signature: currentSignature,
    };
  } else {
    return null;
  }
};

export default getBasketSession;
