import { getCurrentLocaleName } from '../services/getLocaleName';

export default class SessionModel {
  id: number;
  dateStart: Date;
  dateStop: Date;
  language: string | null;
  performers: string[] | null;
  placementEventKey: string | null;
  places: string[] | null;
  qtyRemaining: number | null;
  qtySold: number | null;
  quotaMax: number | null;
  quantity: number;
  totalPrice: number;

  constructor(data: any) {
    this.id = data.id;
    this.dateStart = new Date(data.dateStart);
    this.dateStop = new Date(data.dateStop);
    this.language = data.locale ? getCurrentLocaleName(data.locale) : null;
    this.performers = data.performers ? getArrayName(data.performers) : [];
    this.placementEventKey = data.placementEventKey;
    this.places = data.places ? getArrayName(data.places) : [];
    this.qtyRemaining = data.qtyRemaining;
    this.qtySold = data.qtySold ? data.qtySold : null;
    this.quotaMax = data.quotaMax ? data.quotaMax : null;
    this.quantity = data.quantity ? data.quantity : 0;
    this.totalPrice = data.totalPrice ? data.totalPrice : 0;
  }

  static setSessionModel(sessions) {
    return sessions
      .map((session) => new SessionModel(session))
      .sort((first, second) => {
        if (first.dateStart < second.dateStart) {
          return -1;
        }

        if (first.dateStart > second.dateStart) {
          return 1;
        }

        if (first.dateStop < second.dateStop) {
          return -1;
        }

        if (first.dateStop > second.dateStop) {
          return 1;
        }

        return 0;
      });
  }
}

const getArrayName = (array) => {
  return array.map((data) => data.name);
};
