import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { basketConstants } from '../constants/basket.constants';
import {
  getBasketSuccess,
  getBasketFailure,
  postBasketItemSuccess,
  postBasketItemFailure,
  putBasketItemSuccess,
  putBasketItemFailure,
  postBasketDiscountSuccess,
  postBasketDiscountFailure,
  deleteAllBasketSuccess,
  deleteAllBasketFailure,
  postBasketAccommodationSuccess,
  postBasketAccommodationFailure,
  postBasketMultipleItemSuccess,
  postBasketMultipleItemFailure,
} from '../actions/basket.actions';
import { get, post, update, del, getUrl } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';
import getBasketSession from '../../services/basketSession';
import { removeBasketStorage } from '../../services/handleBasketStorage';
import { getNewBasketToken } from '../../services/handleStorage';

export async function getBasketFetch(arg) {
  const url = shopConfig.router.basketDetailUrl;
  return await get(getUrl(url, arg.basketSession));
}

export async function postBasketItemFetch(arg) {
  const url = shopConfig.router.basketAddOneItemUrl;
  return await post(getUrl(url, arg.basketSession), arg.basketItem);
}

export async function postBasketMultipleItemFetch(arg) {
  const url = shopConfig.router.basketAddMultiItemUrl;
  return await post(getUrl(url, arg.basketSession), arg.basketItem);
}

export async function putBasketItemFetch(arg) {
  const url = shopConfig.router.basketUpdateItemUrl;
  return await update(getUrl(url, arg.basketSession), arg.basketItem);
}

export async function postBasketDiscountFetch(arg) {
  const url = shopConfig.router.basketAddDiscountUrl;
  return await post(getUrl(url, arg.basketSession), arg.basketDiscount);
}

export async function deleteAllBasketFetch(arg) {
  const url = shopConfig.router.basketRemoveAllUrl;
  return await del(getUrl(url, arg.basketSession));
}

export async function postBasketAccommodationFetch(arg) {
  const url = shopConfig.router.basketAddAccommodationUrl;
  return await post(url, arg.accommodation);
}

export function* fetchGetBasket(action) {
  const basketSession = action.params ?? getBasketSession();

  try {
    let basket = null;
    if (basketSession) {
      basket = yield call(getBasketFetch, {
        basketSession: basketSession,
      });
    }

    yield put(getBasketSuccess(basket));
  } catch (error) {
    if (error.status === 404) {
      removeBasketStorage();
    }
    yield put(getBasketFailure(error));
  }
}

export function* fetchPostBasketItem(action) {
  const basketSession = getBasketSession();

  if (!basketSession) {
    action.basketItem.customer_key =
      shopConfig.pos.newBasketToken ?? getNewBasketToken();
  }

  try {
    const newBasketItem = yield call(postBasketItemFetch, {
      basketItem: action.basketItem,
      basketSession: basketSession,
    });
    yield put(postBasketItemSuccess(newBasketItem));
  } catch (error) {
    yield put(postBasketItemFailure(error));
  }
}

export function* fetchPostBasketMultipleItem(action) {
  const basketSession = getBasketSession();

  if (!basketSession) {
    action.basketItem.customer_key =
      shopConfig.pos.newBasketToken ?? getNewBasketToken();
  }

  try {
    const newBasketMultipleItem = yield call(postBasketMultipleItemFetch, {
      basketItem: action.basketItem,
      basketSession: basketSession,
    });
    yield put(postBasketMultipleItemSuccess(newBasketMultipleItem));
  } catch (error) {
    yield put(postBasketMultipleItemFailure(error));
  }
}

export function* fetchPutBasketItem(action) {
  try {
    const updatedBasketItem = yield call(putBasketItemFetch, {
      basketItem: action.basketItem,
      basketSession: getBasketSession(),
    });
    yield put(putBasketItemSuccess(updatedBasketItem));
  } catch (error) {
    yield put(putBasketItemFailure(error));
  }
}

export function* fetchPostBasketDiscount(action) {
  try {
    const newBasketDiscount = yield call(postBasketDiscountFetch, {
      basketDiscount: action.basketDiscount,
      basketSession: getBasketSession(),
    });
    yield put(postBasketDiscountSuccess(newBasketDiscount));
  } catch (error) {
    yield put(postBasketDiscountFailure(error));
  }
}

export function* fetchDeleteAllBasket() {
  try {
    const deletedBasket = yield call(deleteAllBasketFetch, {
      basketSession: getBasketSession(),
    });
    yield put(deleteAllBasketSuccess(deletedBasket));
  } catch (error) {
    yield put(deleteAllBasketFailure(error));
  }
}

export function* fetchPostBasketAccommodation(action) {
  try {
    const updatedBasketItem = yield call(postBasketAccommodationFetch, {
      accommodation: action.accommodation,
    });
    yield put(postBasketAccommodationSuccess(updatedBasketItem));
  } catch (error) {
    yield put(postBasketAccommodationFailure(error));
  }
}

export function* getBasket() {
  yield takeLatest(basketConstants.GET_BASKET_REQUEST, fetchGetBasket);
}

export function* postBasketItem() {
  yield takeLatest(
    basketConstants.POST_BASKET_ITEM_REQUEST,
    fetchPostBasketItem
  );
}

export function* postBasketMultipleItem() {
  yield takeLatest(
    basketConstants.POST_BASKET_MULTIPLE_ITEM_REQUEST,
    fetchPostBasketMultipleItem
  );
}

export function* putBasketItem() {
  yield takeLatest(basketConstants.PUT_BASKET_ITEM_REQUEST, fetchPutBasketItem);
}

export function* postBasketDiscount() {
  yield takeLatest(
    basketConstants.POST_BASKET_DISCOUNT_REQUEST,
    fetchPostBasketDiscount
  );
}

export function* deleteAllBasket() {
  yield takeLatest(
    basketConstants.DELETE_ALL_BASKET_REQUEST,
    fetchDeleteAllBasket
  );
}

export function* postBasketAccommodation() {
  yield takeLatest(
    basketConstants.POST_BASKET_ACCOMMODATION_REQUEST,
    fetchPostBasketAccommodation
  );
}

export default function* basketSaga() {
  yield all([
    fork(getBasket),
    fork(postBasketItem),
    fork(postBasketMultipleItem),
    fork(putBasketItem),
    fork(postBasketDiscount),
    fork(deleteAllBasket),
    fork(postBasketAccommodation),
  ]);
}
