import RequiredItemModel from './RequiredItemModel';

export default class ItemModel {
  formuleCanonicalId: number;
  formuleId: number;
  formuleMaxQty: number;
  formuleMinQty: number;
  id: number;
  isDiscount: boolean;
  isFees: boolean;
  isLocked: boolean;
  name: string;
  price: number;
  priceBrutWithVat: number;
  priceBrutWithoutVat: number;
  priceUnit: number;
  priceUnitWithVat: number;
  priceUnitWithoutVat: number;
  priceWithVat: number;
  priceWithoutVat: number;
  quantity: number;
  reservation: number | null;
  sessionId: number | null;
  sessionTxt: string | null;
  placementInfos?: { unitKey: string }[] | null;
  formuleRelationRequired?: RequiredItemModel[] | null;

  constructor(data: any) {
    this.formuleCanonicalId = data.formuleCanonicalId;
    this.formuleId = data.formuleId;
    this.formuleMaxQty = data.formuleMaxQty;
    this.formuleMinQty = data.formuleMinQty;
    this.id = data.id;
    this.isDiscount = data.isDiscount;
    this.isFees = data.isFees;
    this.isLocked = data.isLocked;
    this.name = data.name;
    this.price = data.price;
    this.priceBrutWithVat = data.priceBrutWithVat;
    this.priceBrutWithoutVat = data.priceBrutWithoutVat;
    this.priceUnit = data.priceUnit;
    this.priceUnitWithVat = data.priceUnitWithVat;
    this.priceUnitWithoutVat = data.priceUnitWithoutVat;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.quantity = data.quantity;
    this.reservation = data.reservation;
    this.sessionId = data.sessionId;
    this.sessionTxt = data.sessionTxt;
    this.placementInfos = data?.placementInfos;
    this.formuleRelationRequired = data?.formuleRelationRequired?.map(
      (item) => new RequiredItemModel(item)
    );
  }
}
