import { customerConstants } from '../constants/customer.constants';

export const getCustomerFormRequest = () => ({
  type: customerConstants.GET_CUSTOMER_FORM_REQUEST,
});

export const getCustomerFormSuccess = (form: object) => ({
  type: customerConstants.GET_CUSTOMER_FORM_SUCCESS,
  form,
});

export const getCustomerFormFailure = (error: object) => ({
  type: customerConstants.GET_CUSTOMER_FORM_FAILURE,
  error,
});

export const postCustomerFormRequest = (form: object) => ({
  type: customerConstants.POST_CUSTOMER_FORM_REQUEST,
  form,
});

export const postCustomerFormSuccess = (response: object) => ({
  type: customerConstants.POST_CUSTOMER_FORM_SUCCESS,
  response,
});

export const postCustomerFormFailure = (error: object) => ({
  type: customerConstants.POST_CUSTOMER_FORM_FAILURE,
  error,
});
