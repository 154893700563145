import taxDetailModel from '../taxDetailModel';

export default class PaymentBasketModel {
  id: number;
  subTotal: number;
  feeAmount: number;
  taxDetails: taxDetailModel[];
  totalPrice: number;
  totalPriceWithVat: number;
  totalPriceWithoutVat: number;

  constructor(data: any) {
    this.id = data.id;
    this.subTotal = data.subTotal;
    this.feeAmount = data.feeAmount;
    this.taxDetails = taxDetailModel.separateObject(data.taxDetails);
    this.totalPrice = data.totalPrice;
    this.totalPriceWithVat = data.totalPriceWithVat;
    this.totalPriceWithoutVat = data.totalPriceWithoutVat;
  }
}
