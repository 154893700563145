export default class PaymentMethodModel {
  id: number;
  description: string;
  name: string;
  picture: string;

  constructor(data: any) {
    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
    this.picture = data.pictureWebPath;
  }

  static setPaymentMethodModel(methods: any) {
    if (methods) {
      return methods.map((method: any) => new PaymentMethodModel(method));
    }

    return [];
  }
}
