import React from 'react';
import { Button, ButtonProps } from 'antd';
import './EmisysButton.css';

interface EmisysButtonProps {
  isOutlined?: boolean;
}
const EmisysButton = ({
  children,
  isOutlined,
  className,
  href,
  ...otherProps
}: EmisysButtonProps & ButtonProps) => {
  let outlined = isOutlined !== undefined ? 'outline' : '';
  let hasClass = className !== undefined ? className : '';

  return (
    <Button
      {...otherProps}
      href={href}
      type={href ? 'link' : 'primary'}
      className={`shop-btn-add ${outlined} ${hasClass}`}
    >
      {children}
    </Button>
  );
};

export default EmisysButton;
