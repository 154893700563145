import QuestionModel from './QuestionModel';

export default class AttendeeFormModel {
  saleId: number;
  formId: number;
  saleKey: string;
  saleName: string;
  questions: QuestionModel[];

  constructor(data: any) {
    this.saleId = data.saleId;
    this.formId = data.formId;
    this.saleKey = data.saleKey;
    this.saleName = data.saleName;
    this.questions = data.questions.map(
      (question) => new QuestionModel(question)
    );
  }
}
