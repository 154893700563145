import { sectionsConstants } from '../constants/sections.constants';

const initialState = {
  current: null,
  sectionsList: null,
};

const sections = (state = initialState, action: any) => {
  switch (action.type) {
    case sectionsConstants.SET_CURRENT_SECTION: {
      return {
        ...state,
        current: action.section,
      };
    }

    case sectionsConstants.GET_SECTIONS_REQUEST: {
      return {
        ...state,
        sectionsList: { sections: [], error: false, loading: true },
      };
    }

    case sectionsConstants.GET_SECTIONS_SUCCESS: {
      return {
        ...state,
        sectionsList: {
          sections: action.sections,
          error: false,
          loading: false,
        },
      };
    }

    case sectionsConstants.GET_SECTIONS_FAILURE: {
      return {
        ...state,
        sectionsList: {
          sections: null,
          error: true,
          errorMessage: action.error?.body?.error,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default sections;
