import { paymentConstants } from '../constants/payment.constants';

export const getPaymentInfoRequest = (params: object) => ({
  type: paymentConstants.GET_PAYMENT_INFO_REQUEST,
  params,
});

export const getPaymentInfoSuccess = (result: object) => ({
  type: paymentConstants.GET_PAYMENT_INFO_SUCCESS,
  result,
});

export const getPaymentInfoFailure = (error: object) => ({
  type: paymentConstants.GET_PAYMENT_INFO_FAILURE,
  error,
});
