import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Container from '../../components/Container/Container';
import { basketTabs, pagesConstant } from '../../Constants/Constants';
import BasketReview from '../../app/Basket/BasketReview';
import CustomerForm from '../../app/Basket/CustomerForm';
import AttendeeForm from '../../app/Basket/AttendeeForm';
import Payment from '../../app/Basket/Payment';
import { connect } from 'react-redux';
import { checkReduxResponse } from '../../services/httpService';
import Translator from '../../services/translator';
import ScrollToTop from '../../services/ScrollToTop';
import {
  getLastVisitedItemStorage,
  setLastVisitedItemStorage,
} from '../../services/handleStorage';
import BasketRecapModal from '../Modal/BasketRecapModal';
import { ShoppingCartOutlined } from '@ant-design/icons';
import history from '../../services/history';
import { openNotificationError } from '../Notification/Notification';
import './BreadCrumb.css';

class BasketBreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.fullBreadcrumbList = [
      {
        name: basketTabs.VALIDATION,
        title: Translator.trans('navbar.validate'),
        isVisible: () => true,
      },
      {
        name: basketTabs.CUSTOMER,
        title: Translator.trans('navbar.information'),
        isVisible: () => true,
      },
      {
        name: basketTabs.ATTENDEE,
        title: Translator.trans('navbar.purchases'),
        isVisible: (basket) => basket && basket.basket?.hasAttendeesForms,
      },
      {
        name: basketTabs.PAYMENT,
        title: Translator.trans('navbar.payment'),
        isVisible: () => true,
      },
    ];
    this.state = {
      currentCrumbItem: null,
      customerForm: null,
      customerInfo: null,
      attendeesForm: null,
      invoiceInfo: null,
      attendeesInfo: null,
      showRecapModal: false,
      breadcrumbList: this.filterBreadcrumbList(),
    };
  }

  componentDidMount() {
    if (this.props.currentBasket) {
      if (
        !this.props.currentBasket.loading &&
        !this.props.currentBasket.error
      ) {
        this.updateBreadcrumbList(this.props.currentBasket.basket);
      }
    }

    const hash = window.location.hash.substring(1);
    const currentItem = this.getCurrentItemFromList(hash);
    const lastVisitedItem = getLastVisitedItemStorage();

    if (lastVisitedItem?.step <= currentItem?.step) {
      const hash = window.location.hash.substring(1);
      this.goToBreadcrumbTab(hash);
    } else {
      this.goToBreadcrumbTab(basketTabs.VALIDATION);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        this.updateBreadcrumbList(this.props.currentBasket.basket);
      }
      if (this.props.currentBasket.error) {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          this.props.currentBasket.error
        );

        if (!this.props.currentBasket.hasOwnProperty('validDiscount')) {
          history.navigate(pagesConstant.tickets.home);
        }
      }
    }
  }

  filterBreadcrumbList = () => {
    let breadCrumbList = this.fullBreadcrumbList.filter((element) =>
      element.isVisible(this.props.currentBasket)
    );
    breadCrumbList.forEach((element, index) => (element.step = index + 1));
    return breadCrumbList;
  };

  updateBreadcrumbList = (currentBasket) => {
    this.setState({ breadcrumbList: this.filterBreadcrumbList() });

    if (currentBasket?.editable) {
      if (this.state.currentCrumbItem) {
        this.goToBreadcrumbTab(this.state.currentCrumbItem.name);
      } else {
        this.goToBreadcrumbTab(basketTabs.VALIDATION);
      }
    }

    if (!currentBasket?.editable) {
      this.goToBreadcrumbTab(basketTabs.PAYMENT);
    }
  };

  goToNextStep = () => {
    const { breadcrumbList } = this.state;
    let currentIndex = breadcrumbList.indexOf(this.state.currentCrumbItem);

    if (currentIndex > -1) {
      this.goToBreadcrumbTab(breadcrumbList[currentIndex + 1].name);
    } else {
      this.goToBreadcrumbTab(breadcrumbList[0].name);
    }
  };

  goToBreadcrumbTab = (location) => {
    this.handleChangeCurrentItem(location);
    this.getLastVisitedItem(location);
  };

  handleChangeCurrentItem = (item) => {
    if (this.getCurrentItemFromList(item)) {
      window.history.pushState('', '', '#' + item);
      this.setState({ currentCrumbItem: this.getCurrentItemFromList(item) });
    } else {
      this.setState({ currentCrumbItem: this.state.breadcrumbList[0] });
    }
  };

  getLastVisitedItem = (item) => {
    if (this.getCurrentItemFromList(item)) {
      setLastVisitedItemStorage(this.getCurrentItemFromList(item));
    } else {
      setLastVisitedItemStorage(this.state.breadcrumbList[0]);
    }
  };

  getCurrentItemFromList = (item) => {
    return this.state.breadcrumbList.find(
      (breadcrumb) => breadcrumb.name === item
    );
  };

  updateBasketReview = () => {
    this.getLastVisitedItem(basketTabs.VALIDATION);
  };

  updateCustomerForm = (data) => this.setState({ customerForm: data });
  updateAttendeeForm = (data) => this.setState({ attendeesForm: data });

  updateCustomerInfo = (data) => {
    this.setState({ customerInfo: data });
    this.getLastVisitedItem(basketTabs.CUSTOMER);
  };

  updateAttendeesInfo = (data) => {
    this.setState({ attendeesInfo: data });
    this.getLastVisitedItem(basketTabs.ATTENDEE);
  };

  updateInvoiceInfo = (data) => this.setState({ invoiceInfo: data });

  render() {
    const {
      currentCrumbItem,
      breadcrumbList,
      customerForm,
      attendeesForm,
      customerInfo,
      attendeesInfo,
      invoiceInfo,
      showRecapModal,
    } = this.state;
    const { basketEditable } = this.props;
    const lastVisitedItem = getLastVisitedItemStorage();

    return (
      <>
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={24}>
            <Container className="breadcrumb-container">
              {basketEditable ? (
                currentCrumbItem &&
                lastVisitedItem &&
                breadcrumbList.map((item, index) => (
                  <div
                    key={index}
                    onClick={
                      item.step <= lastVisitedItem.step
                        ? () => this.handleChangeCurrentItem(item.name)
                        : null
                    }
                    className={`gutter-row breadcrumb-item ${
                      item.name === currentCrumbItem.name && 'active '
                    }
                    ${item.step < currentCrumbItem.step && 'visited '}
                    ${item.step > lastVisitedItem.step && 'disabled '}
                    `}
                  >
                    <div>
                      {index + 1} - {item.title}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className={'gutter-row breadcrumb-item active'}
                  onClick={() => this.setState({ showRecapModal: true })}
                >
                  <ShoppingCartOutlined />
                  {Translator.trans('order.title')}
                </div>
              )}
            </Container>
          </Col>
        </Row>

        {currentCrumbItem && (
          <ScrollToTop trigger={currentCrumbItem}>
            <Row gutter={16}>
              {currentCrumbItem.name === basketTabs.VALIDATION && (
                <BasketReview
                  goToNextStep={this.goToNextStep}
                  updateBasketReview={this.updateBasketReview}
                />
              )}
              {currentCrumbItem.name === basketTabs.CUSTOMER && (
                <CustomerForm
                  updateCustomerForm={this.updateCustomerForm}
                  updateCustomerInfo={this.updateCustomerInfo}
                  form={customerForm}
                  customerInfo={customerInfo}
                  goToNextStep={this.goToNextStep}
                />
              )}
              {currentCrumbItem.name === basketTabs.ATTENDEE && (
                <AttendeeForm
                  updateAttendeeForm={this.updateAttendeeForm}
                  updateAttendeesInfo={this.updateAttendeesInfo}
                  form={attendeesForm}
                  attendeesInfo={attendeesInfo}
                  goToNextStep={this.goToNextStep}
                />
              )}
              {currentCrumbItem.name === basketTabs.PAYMENT && (
                <Payment
                  basketEditable={basketEditable}
                  updateInvoiceInfo={this.updateInvoiceInfo}
                  invoiceInfo={invoiceInfo}
                />
              )}
            </Row>
          </ScrollToTop>
        )}

        {!basketEditable && (
          <BasketRecapModal
            isOpen={showRecapModal}
            onClose={() => this.setState({ showRecapModal: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
});

export default connect(mapStateToProps)(BasketBreadCrumb);
